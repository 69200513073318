import './recorded-workshop-header-video.css'

import {
  getDefaultPoster,
  getDefaultSrc,
  LockedChapterView,
} from 'components/step-list-interactable-unpaid/step-list-interactable-unpaid.js'

document.addEventListener('DOMContentLoaded', () => {
  const containerSelector = '.c-recorded-workshop-header-video'
  const container = document.querySelector(containerSelector)
  if (container === null) return
  const videoElem = document.querySelector(`${containerSelector} video-js`)
  const player = videojs.getPlayer(videoElem)
  const initialSrc = getDefaultSrc()
  const initialPoster = getDefaultPoster()
  if (!!initialSrc) {
    if (initialPoster) {
      player.poster(initialPoster)
    }
    player.src(initialSrc)
    const overlayContent = $('.bcove_embed__custom-overlay-content').html()
    const lockedChapterView = new LockedChapterView(player, overlayContent)
  }
})
