import './post-form-upload-images.css'

export function handleUploadedImages() {
  const container = $('.file_container').append(
    `<input accept="image/jpeg, image/jpg, image/gif, image/png"
      class="postimages" multiple="multiple" name="post[images][]"
      type="file"/>`
  )
  container.find('input').change(handleUploadedImages)

  if (this.files && this.files[0]) {
    $(this.files).each(function () {
      const reader = new FileReader()
      reader.readAsDataURL(this)
      reader.onload = function (e) {
        $('.post_file_image_preview_div').css({ display: 'flex' })
        $('.post_file_image_preview_div').append(`<img class='post_file_image_preview' src='${e.target.result}'>`)
      }
    })
  }
}
