/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  // Filter subtypes based on selected type when user is creating/editing a
  // resource
  if ($('.resources.new').length || $('.resources.edit').length) {
    const subtypes = $('#resource_subtype_id').html()
    const update_subtypes = function () {
      const selected_type = $('#resource_type_id :selected').text()
      const options = $(subtypes).filter(`optgroup[label='${selected_type}']`).html()
      if (options) {
        return $('#resource_subtype_id').html(options)
      } else {
        return $('#resource_subtype_id').empty()
      }
    }

    $('#resource_type_id').change(function () {
      update_subtypes()
    })

    update_subtypes()
  }
})
