const queryStringParser = require('query-string')

const utm_types = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

// arg: query string
export default function parseUTMS(queryString) {
  const parsedQueryString = queryStringParser.parse(queryString)
  return Object.keys(parsedQueryString)
    .filter((key) => utm_types.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: parsedQueryString[key],
      }
    }, {})
}
