import './post-form-select-tag.css'

export function addPostFormSelectTagListener(postData) {
  const marketplaceVals = [postData.marketplaceForsale, postData.marketplaceInsearchof, postData.marketplaceFree]

  $('#post_tag').change(function () {
    const option = this.options[this.selectedIndex]
    if (marketplaceVals.includes(option.value)) {
      if (option.text.indexOf('Marketplace') === -1) {
        if (option.value === postData.marketplaceForsale) $('#post_title').val('For Sale: ' + $('#post_title').val())
        else if (option.value === postData.marketplaceInsearchof) $('#post_title').val('ISO: ' + $('#post_title').val())
        else $('#post_title').val('For Free: ' + $('#post_title').val())
        option.text = `Marketplace: ${option.text.trim()}`
      }
    }
  })
}

export function toggleAnnouncementOptions(announcement, toggled = true) {
  const tagSelector = '#post_tag' // later convert to class
  const $announcementOption = $(`${tagSelector} option[value*='${announcement}']`)
  if (toggled) {
    $announcementOption.removeClass('hide')
  } else {
    $announcementOption.addClass('hide')
  }
}

export function toggleSpecialOptions(toggled = true) {
  const tagSelector = '#post_tag' // later convert to class
  const $standardOptions = $(`${tagSelector} option:not(.special-category)`)
  const $specialOptions = $(`${tagSelector} option.special-category`)
  if (toggled) {
    $specialOptions.removeClass('hide')
    $standardOptions.addClass('hide')
  } else {
    $specialOptions.addClass('hide')
    $standardOptions.removeClass('hide')
  }
}

export function cleanSelection() {
  const tagSelector = '#post_tag' // later convert to class
  if (checkHiddenSelection($(`${tagSelector} option`), $(tagSelector).val())) resetSelected(tagSelector)
}

function resetSelected(tagSelector) {
  const firstOption = $(`${tagSelector} option:not(.hide, [value=""])`).val()
  $(tagSelector).val(firstOption)
}

function checkHiddenSelection($options, value) {
  if ($options.length === 0) return false
  return $options.filter(`.hide[value='${value}']`).length
}
