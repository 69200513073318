// depends on jQuery
import intlTelInput from 'intl-tel-input'

$(function () {
  $('.js-expert-bio-read-more').click(function () {
    $('.js-truncated-bio').css('display', 'none')
    $('.js-full-bio').css('display', 'block')
    $('.js-expert-bio-read-more').css('display', 'none')
    $('.js-expert-bio-read-less').css('display', 'inline')
  })
  $('.js-expert-bio-read-less').click(function () {
    $('.js-full-bio').css('display', 'none')
    $('.js-truncated-bio').css('display', 'block')
    $('.js-expert-bio-read-more').css('display', 'inline')
    $('.js-expert-bio-read-less').css('display', 'none')
  })
})

$(function () {
  if (!$('.reviews.experts .js-single-paginator').length) {
    return
  }
  // This ensures that the reviews section is flush with the bottom of the page
  // if expert reviews are present
  $('#body-yield').css('margin-bottom', '0px')
  $('.reviews.experts .js-single-paginator').each(function () {
    const $paginator = $(this)
    setInterval(function () {
      let $next = $paginator.children('.selected').next()
      if (!$next.length) {
        $next = $paginator.children().first()
      }
      singlePaginator($next)
    }, 4000)
  })

  $('.reviews.experts .js-single-paginator button').click(function () {
    if (!$(this).hasClass('selected')) {
      singlePaginator($(this))
    }
  })

  var singlePaginator = function ($btn) {
    const $container = $btn.parent().parent('.js-footer').siblings('.js-wrapper')
    const targetPage = parseInt($btn.data('page'))
    // include margin in width of box + 3 for space in between sections
    const xDiff = -1 * targetPage * $container.find('li').outerWidth(true)
    $container.find('li').css('transform', `translate(${parseInt(xDiff)}px, 0)`)
    $btn.siblings('.selected').removeClass('selected')
    $btn.addClass('selected')
  }
})

$(function () {
  $('#changepassword').click(function () {
    $('#passworddiv').slideDown()
  })
  // This is for previewing an attached image
  const readURLExpertUser = function (input) {
    if (input.files && input.files[0]) {
      const reader = new FileReader()

      reader.onload = function (e) {
        $('#user_image_preview').attr('src', e.target.result)
        $('#original_user_image').css({ display: 'none' })
        $('#user_image_preview').css({ display: 'inline-block' })
      }

      reader.readAsDataURL(input.files[0])
    }
  }

  $('#expert_expert_user_image').change(function () {
    readURLExpertUser(this)
  })
})

$(function () {
  if ($('.users.edit #expert_user_phone_number').length) {
    let iti = intlTelInput(document.querySelector('#expert_user_phone_number'), {
      initialCountry: $('#expert_user_phone_number').data('default-country'),
      formatOnDisplay: true,
      separateDialCode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/js/utils.js',
    })

    $('#expert_user_phone_number')
      .parents('form')
      .submit(function () {
        if ($('#expert_user_phone_number').val().length) {
          $('#expert_user_phone_number').val(iti.getNumber())
        }
      })
  }
})
