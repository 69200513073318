$(function () {
  $('.notification-settings.general form.check input[type="submit"]').click(function () {
    const notAttemptingUnsubscribe = function () {
      const $selectedOptions = $(`form.check option:checked[value='${gon.notif_settings.none}']`)
      if ($selectedOptions.length === 0) {
        return true
      }
      // Check group ids the user is unsubscribing from - parent.sibling is id
      // elem, return false if any group id was not already unsubscribed
      return $selectedOptions
        .parent()
        .siblings('input')
        .get()
        .every((elem) => gon.notif_settings.existing_ids.indexOf($(elem).val()) >= 0)
    }
    if (notAttemptingUnsubscribe()) {
      $(this).removeAttr('data-confirm').trigger('click.rails')
    }
  })
})
