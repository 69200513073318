const selector = '.p-gifts-landing'
$(() => {
  if (!document.querySelector(selector)) return

  $(`${selector}__submit-js`).click(() => {
    const $form = $(`${selector}__submit-js`).parents('form')
    const amount = $form.find('input[type="radio"]:checked').val()
    const path = $form.attr('action')
    $form.attr('action', path.replace('100', amount))
    $form.submit()
  })
})
