import './stripe-bank-form.css'

/*
NOTE: Uses stripe v2 js
Included in the html file
- not a easy way to upgrade - https://stripe.com/docs/stripe-js/v2#collecting-bank-account-details
*/

$(function () {
  if (!document.querySelector('.c-stripe-bank-form')) return
  if (typeof gon.stripe_publishable_key !== 'undefined') {
    Stripe.setPublishableKey(gon.stripe_publishable_key)
  }
})

const stripeResponseHandler = function (status, response) {
  const $form = $('.c-stripe-bank-form form')
  if (response.error) {
    $form.find('.bank-errors').text(response.error.message)
    $form.find('button').prop('disabled', false)
  } else {
    const token = response.id
    $form.append($('<input type="hidden" name="stripeBankToken" />').val(token))
    $form.get(0).submit()
  }
}

// Now the handler is done, lets use it when the form is submitted.
// On form submission execute:
$(function () {
  if (!document.querySelector('.c-stripe-bank-form')) return
  $('.c-stripe-bank-form form').submit(function (event) {
    // Get the form object.
    const $form = $(this)
    // Disable the submit button to prevent repeated clicks
    $form.find('button').prop('disabled', true)
    // Create a token with Stripe
    Stripe.bankAccount.createToken($form, stripeResponseHandler)
    // Prevent the form from submitting with the default action
    return false
  })
})

/* end bank form */
