import './subscription-coupon-entry.css'
const superagent = require('superagent')

const classSelector = '.js-subscription-coupon-entry'

function resetCoupon(inputStore) {
  const removeLink = document.querySelector(`${classSelector}__remove-link`)
  const successDiv = document.querySelector(`${classSelector}__message--success p`)
  const errorDiv = document.querySelector(`${classSelector}__message--error p`)
  inputStore.value = ''
  successDiv.innerHTML = ''
  errorDiv.innerHTML = ''
  removeLink.style.display = 'none'
}

function assignCode(inputStore, code, message) {
  const errorDiv = document.querySelector(`${classSelector}__message--error p`)
  const successDiv = document.querySelector(`${classSelector}__message--success p`)
  const removeLink = document.querySelector(`${classSelector}__remove-link`)
  inputStore.value = code
  removeLink.style.display = 'block'
  removeLink.addEventListener('click', function () {
    resetCoupon(inputStore)
  })
  successDiv.innerHTML = message
  errorDiv.innerHTML = ''
}

function errorCode(inputStore, message) {
  const errorDiv = document.querySelector(`${classSelector}__message--error p`)
  const successDiv = document.querySelector(`${classSelector}__message--success p`)
  const removeLink = document.querySelector(`${classSelector}__remove-link`)
  inputStore.value = ''
  successDiv.innerHTML = ''
  removeLink.style.display = 'none'
  errorDiv.innerHTML = message
}

function couponResponse(inputStore, res) {
  if (res.body.result) {
    assignCode(inputStore, res.body.code, res.body.userMessage)
  } else {
    errorCode(inputStore, res.body.userMessage)
  }
}

function submitMessage(form, input, inputStore) {
  superagent
    .get(form.dataset.path)
    .query({ code: input.value })
    .then((res) => {
      couponResponse(inputStore, res)
    })
    .catch((err) => {})
}

document.addEventListener(
  'DOMContentLoaded',
  function () {
    const form = document.querySelector('.js-subscription-coupon-entry__form')
    const inputStore = document.querySelector('#coupon_code')

    if (form) {
      const input = form.querySelector('.js-subscription-coupon-entry__input')
      const submit = form.querySelector('.js-subscription-coupon-entry__submit')

      // You can send a message with cmd/ctrl+enter
      input.addEventListener('keydown', (event) => {
        if (event.keyCode === 13) {
          event.preventDefault()
          submitMessage(form, input, inputStore)
        }
      })

      // Or by cicking a button
      submit.addEventListener('click', (event) => {
        event.preventDefault()
        submitMessage(form, input, inputStore)
      })
    }

    const link = document.querySelector('.js-subscription-coupon-entry__link')
    if (link) {
      link.addEventListener('click', function () {
        form.style.display = 'block'
      })
    }
  },
  false
)
