import './workshop-list-drawers-unpaid.css'

import BadgerAccordion from 'badger-accordion'

$(() => {
  const elems = document.querySelectorAll('.js-c-workshop-list-drawers-unpaid__accordion')
  const accordions = []

  Array.from(elems).forEach((elem) => {
    accordions.push(new BadgerAccordion(elem))
  })

  if (!document.querySelector('.c-workshop-list-drawers-unpaid__takeaway-list')) {
    return
  }

  const takeawayColumnCount = $('.c-workshop-list-drawers-unpaid__takeaway-list')
    .css('grid-template-columns') /* outputs "99px 99px 99px 99px" */
    .split(' ').length
  const rowsShown = window.innerWidth > 767 ? 1 : 2
  const maxShown = takeawayColumnCount * rowsShown

  const $takeawayLists = $('.c-workshop-list-drawers-unpaid__takeaway-list')
  for (let i = 0; i < $takeawayLists.length; i++) {
    const totalTakeaways = $takeawayLists[i].children.length
    if (maxShown < totalTakeaways) {
      const hiddenItemCount = totalTakeaways - maxShown + 1
      $($takeawayLists[i]).find(`> div:nth-of-type(1n+${maxShown})`).addClass('hide')

      const $showMore = $(
        `<div class='c-workshop-list-drawers-unpaid__takeaway-list__show-more'><div>+ ${hiddenItemCount} More</div></div>`
      )
      $($takeawayLists[i]).append($showMore)
    }
  }
})
