import './recaptcha-widget.css'

const getValidatedRecaptcha = () => {
  const recaptcha_response = validToken()
  if (!recaptcha_response) {
    $('.c-recaptcha-widget__error-message').show()
  }
  return recaptcha_response
}

const validToken = () => {
  /* var created in each location _recaptcha-widget.html.erb is rendered */
  /* var <%= modifer_klass %>Recaptcha; */
  if (validRecaptcha(getRecaptchaResponse(modalRecaptcha))) {
    return getRecaptchaResponse(modalRecaptcha)
  }
  if (validRecaptcha(getRecaptchaResponse(footerRecaptcha))) {
    return getRecaptchaResponse(footerRecaptcha)
  }
  if (validRecaptcha(getRecaptchaResponse(handoutRecaptcha))) {
    return getRecaptchaResponse(handoutRecaptcha)
  }
  if (validRecaptcha(getRecaptchaResponse(expertRecaptcha))) {
    return getRecaptchaResponse(expertRecaptcha)
  }
  if (validRecaptcha(getRecaptchaResponse(circleRecaptcha))) {
    return getRecaptchaResponse(circleRecaptcha)
  }
  return false
}

const getRecaptchaResponse = (recaptchaID) => {
  return grecaptcha.getResponse(recaptchaID)
}

const validRecaptcha = (recaptcha_token) => {
  return !(recaptcha_token === '')
}

export default getValidatedRecaptcha
