import './date-field.css'

const selector = '.c-date-field'
$(() => {
  if (!document.querySelector(selector)) return

  if ($(`${selector}__native-date`).prop('type') === 'date') {
    $(`${selector}__native-date`).css('display', 'block')
    $(`${selector}__selects-container`).remove()
  }
})
