// relies on jQuery

window.postsAddListeners = () =>
  $('.js-cancel-post-edit').click(function () {
    const id = $(this).data('id')
    // Show the original post content and hide the edit form
    $('#post' + id + ' .content').show()
    $('#edit-post' + id).remove()
    return false
  })

$(function () {
  if ($('.posts.show').length && shouldAddHeightToPost()) {
    $('.posts.show').css('height', '+=500px')
  }
})

var shouldAddHeightToPost = function () {
  // Add height if mobile chrome
  // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
  const isChromium = window.chrome
  const winNav = window.navigator
  const vendorName = winNav.vendor
  const isOpera = typeof window.opr !== 'undefined'
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1
  const isIOSChrome = winNav.userAgent.match('CriOS')
  return (
    (isIOSChrome ||
      (isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false)) &&
    /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
  )
}
