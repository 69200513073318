import jstz from 'jstz'
import Cookies from 'universal-cookie'

document.addEventListener('DOMContentLoaded', function () {
  const cookies = new Cookies()
  if (!cookies.get('timezone')) {
    let expiration = new Date()
    expiration.setDate(expiration.getDate() + 1)
    cookies.set('timezone', jstz.determine().name(), {
      path: '/',
      expires: expiration,
    })
  }
})
