import Cookies from 'universal-cookie'

const cookies = new Cookies()
const utmData = cookies.get('utm-original')

document.addEventListener('DOMContentLoaded', function () {
  if (gon.just_signed_up) postUTMsUser()
})

function postUTMsUser() {
  if (utmData) {
    fetch('/tracking/user_utm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData(utmData)),
    })
  }
}

function userData(utmData) {
  return Object.assign({}, utmData, {
    authenticity_token: document.querySelector('#utm_authenticity').value,
  })
}
