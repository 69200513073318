import { HandoutLesson } from './handout-lesson'
import { SupplementalVideoLesson } from './supplemental-video-lesson'
import { VideoLesson } from './video-lesson'

export abstract class LessonCreator {
  static createLesson(lessonEl: HTMLDivElement) {
    const type = lessonEl.dataset.type

    switch (type) {
      case 'video':
        return new VideoLesson(lessonEl)
      case 'handout':
        return new HandoutLesson(lessonEl)
      case 'supplemental_video':
        return new SupplementalVideoLesson(lessonEl)
      default:
        throw new Error('Unrecognized lesson type')
    }
  }
}
