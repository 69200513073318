import { LessonsCollection } from './lessons/lessons-collection'
import { LessonsIterator } from './lessons/lessons-iterator'
import { Lesson } from './lessons/lesson'
import { getMoreRecentlyUpdatedLesson } from './helpers/get-more-recently-updated-lesson'
import { VideoPlayerUpdater } from './video-player-updater'
import { HandoutOverlayUpdater } from './handout-overlay-updater'
import { SupplementalVideoOverlayUpdater } from './supplemental-video-overlay-updater'
import { VideoLesson } from './lessons/video-lesson'
import { SupplementalVideoLesson } from './lessons/supplemental-video-lesson'
import { ReviewPromptUpdater } from './review-prompt-updater'

export interface CourseViewUpdater {
  run(subject: CourseView, wasAutoplay?: boolean): void
}

export class CourseView {
  private lessonsCollection: LessonsCollection
  private lessonsIterator: LessonsIterator
  private currentLesson: Lesson
  private nextLesson: Lesson | undefined

  // View updaters (notified on active lesson change)
  private playerUpdater: VideoPlayerUpdater
  private handoutOverlayUpdater: HandoutOverlayUpdater
  private supplementalVideoOverlayUpdater: SupplementalVideoOverlayUpdater
  private reviewPromptUpdater: ReviewPromptUpdater

  constructor(
    lessonsCollection: LessonsCollection,
    playerUpdater: VideoPlayerUpdater,
    handoutOverlayUpdater: HandoutOverlayUpdater,
    supplementalVideoOverlayUpdater: SupplementalVideoOverlayUpdater,
    reviewPromptUpdater: ReviewPromptUpdater
  ) {
    this.playerUpdater = playerUpdater
    this.handoutOverlayUpdater = handoutOverlayUpdater
    this.supplementalVideoOverlayUpdater = supplementalVideoOverlayUpdater
    this.reviewPromptUpdater = reviewPromptUpdater

    this.lessonsCollection = lessonsCollection
    this.lessonsIterator = this.lessonsCollection.getLessonsIterator()
    this.lessonsIterator.jumpToLesson(this.getInitialLesson())
    this.currentLesson = this.lessonsIterator.current()
    this.nextLesson = this.lessonsIterator.getNext()
    this.currentLesson.markActive()

    this.runViewUpdaters(true)

    lessonsCollection.getAllLessons().forEach((lesson) => lesson.onClick(() => this.activate(lesson, false)))
  }

  public getCurrentLesson() {
    return this.currentLesson
  }

  public getCurrentWorkshop() {
    return this.lessonsIterator.currentWorkshop()
  }

  public getNextLesson() {
    return this.nextLesson
  }

  public runViewUpdaters(autoplay: boolean): void {
    this.playerUpdater.run(this)
    this.handoutOverlayUpdater.run(this, autoplay)
    this.supplementalVideoOverlayUpdater.run(this)
    this.reviewPromptUpdater.run(this)
  }

  public activate(lesson: Lesson, wasAutoplay: boolean) {
    const lessons = this.lessonsCollection.getAllLessons()

    lessons.forEach((lsn) => {
      if (lsn.id !== lesson.id) lsn.markInactive()
    })

    this.lessonsIterator.jumpToLesson(lesson)
    this.currentLesson = this.lessonsIterator.current()
    this.nextLesson = this.lessonsIterator.getNext()
    this.currentLesson.markActive()
    this.runViewUpdaters(wasAutoplay)
  }

  private getInitialLesson() {
    const lessons = this.lessonsCollection.getAllLessons()
    // Returns the most recently updated incomplete lesson
    return lessons
      .reverse() // reverse array so we get the first step if all are the same
      .filter((lesson) => !lesson.isComplete)
      .reduce(getMoreRecentlyUpdatedLesson, lessons[0])
  }

  public onLastVideoLessonInWorkshop() {
    const lessons = this.lessonsIterator.currentWorkshop().lessons
    const lessonIsNotVideo = (lesson: Lesson) => !(lesson instanceof VideoLesson)
    const currentLessonIndex = lessons.findIndex((lsn) => lsn.id === this.currentLesson.id)

    if (lessonIsNotVideo(this.currentLesson)) {
      return false
    }

    return lessons.slice(currentLessonIndex + 1).every(lessonIsNotVideo)
  }
}
