import './workshop-filter.css'
import { getDefaultFilter } from './get-default-filter.js'
import { smoothScroll } from 'utils/smoothScroll.js'

const selector = '.c-workshop-filter'
export class WorkshopFilterView {
  constructor(list, pagination) {
    this.list = list
    this.pagination = pagination
    this.changeFilter(getDefaultFilter(selector))
  }

  run() {
    this.addListeners()
  }

  addListeners() {
    const filters = document.querySelectorAll(selector)
    const instance = this
    for (let i = 0; i < filters.length; i++) {
      filters[i].addEventListener('click', (e) => {
        if (!e.target.classList.contains('selected')) {
          instance.changeFilter(e.target.dataset.title)
          if (this.isFooterFilter(e.target)) smoothScroll('.p-workshops-index__classes h1')
        }
        /* clear anchor button styling for mobile */
        return false
      })
    }
  }

  isFooterFilter(elem) {
    return document.querySelector('.c-filterable-workshops__footer').contains(elem)
  }

  changeFilter(title) {
    this.removeSelectedFilters(title)
    this.refilter(title)
    this.addSelectedFilters(title)
  }

  addSelectedFilters(title) {
    this.toggleLowerFooter(title)
    const selectedFilters = document.querySelectorAll(`${selector}[data-title='${title}']`)
    for (let i = 0; i < selectedFilters.length; i++) {
      selectedFilters[i].classList.add('selected')
    }
  }

  toggleLowerFooter(title) {
    const footer = document.querySelector('.c-filterable-workshops__footer .c-filterable-workshops__filters')
    if (title === 'all') {
      footer.classList.remove('hide')
    } else {
      footer.classList.add('hide')
    }
  }

  removeSelectedFilters() {
    const selectedFilters = document.querySelectorAll(`${selector}.selected`)
    for (let i = 0; i < selectedFilters.length; i++) {
      selectedFilters[i].classList.remove('selected')
    }
  }

  refilter(title) {
    this.filterList(title)
    this.pagination.resetPagination()
  }

  filterList(title) {
    if (title === 'all') {
      this.list.filter()
    } else {
      this.list.filter((item) => {
        return item.values().tags.includes(title)
      })
    }
  }
}
