import './footer-signed-out-v1.css'

document.addEventListener('DOMContentLoaded', () => {
  // $(() => {
  const selector = 'footer-signed-out-v1'
  const container = document.querySelector(`.${selector}`)
  if (container && container.classList.contains(`${selector}--email-sign-up`)) {
    if (container.querySelector(`.${selector}__sign-up.hidden`)) {
      removeEmailSignUpModifier(selector, container)
    } else {
      document.querySelector('body').classList.add('footer-email-list-shown')
    }
  }
})

function removeEmailSignUpModifier(selector, container) {
  container.classList.remove(`${selector}--email-sign-up`)
}
