import { smoothScroll } from 'utils/smoothScroll.js'

document.addEventListener('DOMContentLoaded', function () {
  const containerSelector = '.hawke-sem-safety-test-1'
  const container = document.querySelector(containerSelector)
  if (container === null) return

  document.querySelector('.learnbtn').addEventListener('click', function () {
    smoothScroll('.section03')
  })
})
