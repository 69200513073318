const sendStepStatusRequest = (stepId, userId, currentPercent) => {
  if (stepId !== null && userId !== null && currentPercent !== null) {
    fetch(`/steps/${stepId}/status_tracker`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify(eventData(userId, currentPercent)),
    })
  }
}

const eventData = (userId, currentPercent) => ({
  authenticity_token: document.querySelector('#utm_authenticity').value,
  user_id: userId,
  current_percent: currentPercent,
  request_time: new Date().getTime(),
})

export default sendStepStatusRequest
