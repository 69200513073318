export function checkedQuerySelector(parent: Element | Document, selector: string): Element {
  const el = parent.querySelector(selector)
  if (!el) {
    throw new Error(`Selector ${selector} didn't match any elements.`)
  }
  return el
}

export function queryElement<T extends typeof Element>(
  container: Document | Element,
  type: T,
  selector: string
): InstanceType<T> {
  const el = checkedQuerySelector(container, selector)
  if (!(el instanceof type)) {
    throw new Error(`Selector ${selector} matched ${el} which is not an ${type} but is instead an ${typeof el}`)
  }
  return el as InstanceType<T>
}

export function queryElementAll<T extends typeof Element>(
  container: Document | Element,
  type: T,
  selector: string
): NodeListOf<InstanceType<T>> {
  const nodeList = container.querySelectorAll(selector)
  const nodeArray = Array.from(nodeList)

  if (nodeArray.length > 0 && !(nodeArray[0] instanceof type)) {
    throw new Error(`Selector ${selector} matched ${nodeArray[0]} which is not an ${type}`)
  }

  return nodeList as NodeListOf<InstanceType<T>>
}
