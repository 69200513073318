import intlTelInput from 'intl-tel-input'

$(function () {
  if ($('#contact-screen').length) {
    let iti = intlTelInput(document.querySelector('#phone_number'), {
      formatOnDisplay: true,
      separateDialCode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/js/utils.js',
    })
    $('#contact-screen')
      .parents('form')
      .submit(function () {
        if ($('#phone_number').val().length) {
          $('#full_phone_number').val(iti.getNumber())
        }
      })
  }
})
