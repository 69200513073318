import { queryElement } from '../../querySelectorHelpers'

const CLASS_NAME_PREFIX = 'c-panel-video-player-supplemental-video-overlay'
const ACTIVE_CLASS_NAME = `${CLASS_NAME_PREFIX}--active`
const INITIAL_SELECTOR = `.${CLASS_NAME_PREFIX}`
const TITLE_SELECTOR = `${INITIAL_SELECTOR}__title`
const SUBTITLE_SELECTOR = `${INITIAL_SELECTOR}__subtitle`
const IMAGE_SELECTOR = `${INITIAL_SELECTOR}__image`
const BUTTON_SELECTOR = `${INITIAL_SELECTOR}__btn`

export class SupplementalVideoOverlay {
  private supplementalVideoOverlayEl: HTMLDivElement
  private nextLessonLink: HTMLAnchorElement
  private nextStepCallbackFn?: () => void

  constructor(supplementalVideoOverlayEl: HTMLDivElement) {
    this.supplementalVideoOverlayEl = supplementalVideoOverlayEl
    this.nextLessonLink = queryElement(
      supplementalVideoOverlayEl,
      HTMLAnchorElement,
      `${INITIAL_SELECTOR}__next-lesson`
    )
  }

  show(title: string, subtitle: string, previewImageUrl: string, onStart: () => void, nextStepCallbackFn?: () => void) {
    this.supplementalVideoOverlayEl.classList.add(ACTIVE_CLASS_NAME)
    this.nextStepCallbackFn = nextStepCallbackFn

    this.getTitleEl().innerHTML = title
    this.getSubtitleEl().innerHTML = subtitle
    this.getImageEl().style.backgroundImage = `url(${previewImageUrl})`
    this.getButtonEl().addEventListener('click', () => onStart())

    if (this.nextStepCallbackFn === undefined) {
      this.nextLessonLink.classList.add('hide')
    } else {
      this.nextLessonLink.addEventListener('click', this.nextStepCallbackFn)
      this.nextLessonLink.classList.remove('hide')
    }
  }

  hide() {
    if (this.nextStepCallbackFn) {
      this.nextLessonLink.removeEventListener('click', this.nextStepCallbackFn)
    }
    this.supplementalVideoOverlayEl.classList.remove(ACTIVE_CLASS_NAME)
  }

  isVisible() {
    return this.supplementalVideoOverlayEl.classList.contains(ACTIVE_CLASS_NAME)
  }

  isHidden() {
    return !this.isVisible()
  }

  private getTitleEl() {
    return queryElement(this.supplementalVideoOverlayEl, HTMLSpanElement, TITLE_SELECTOR)
  }

  private getSubtitleEl() {
    return queryElement(this.supplementalVideoOverlayEl, HTMLSpanElement, SUBTITLE_SELECTOR)
  }

  private getImageEl() {
    return queryElement(this.supplementalVideoOverlayEl, HTMLDivElement, IMAGE_SELECTOR)
  }

  private getButtonEl() {
    return queryElement(this.supplementalVideoOverlayEl, HTMLAnchorElement, BUTTON_SELECTOR)
  }
}
