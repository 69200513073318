/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import autosize from 'autosize'
if (!window.feed) {
  window.feed = {}
}

const showComments = function (feed_item_id) {
  $('#comments_section_' + feed_item_id).slideDown()
  autosize($('#comments_section_' + feed_item_id + ' textarea'))
}

window.feed.showComments = (feed_item_id) => showComments(feed_item_id)

const addListeners = function (feed_item_id) {
  if (feed_item_id === null || feed_item_id.length === 0) {
    return
  }
  $('#cancel_comment_' + feed_item_id).click(function () {
    if ($('#comments_section_' + feed_item_id + ' #comment_content').val()) {
      $('#cancel_comment_' + feed_item_id).attr('data-confirm', 'Are you sure you want to discard this comment?')
      $('#cancel_comment_' + feed_item_id).click()
    } else {
      $('#cancel_comment_' + feed_item_id).removeAttr('data-confirm')
      $('#comments_section_' + feed_item_id + ' .comment_controls').slideUp()
    }
  })
  // Listen for custom click from confirmationdialogs.js.coffee
  $(document).on('click', '#cancel_comment_' + feed_item_id + '_confirm', function () {
    $('#comments_section_' + feed_item_id + ' #comment_content').val('')
    $('#comments_section_' + feed_item_id + ' .comment_controls').slideUp()
  })
  $('.showcomments_' + feed_item_id).click(function () {
    showComments(feed_item_id)
  })
  $('#comments_section_' + feed_item_id + ' #comment_content').click(function () {
    $('#comments_section_' + feed_item_id + ' .comment_controls').slideDown()
  })
  $(`.showcomments_${feed_item_id} #${feed_item_id}_user_comment_link`).click(function (e) {
    e.stopPropagation()
  })
  // Show "Show More" link if needed
  if ($('#' + feed_item_id + ' #pspacing').height() === 198) {
    $('#' + feed_item_id + ' .showmorelink').css({ display: 'inline' })
  }
  // Clicking on post body should expand it if there are ellipsis
  $('#' + feed_item_id + ' #pspacing').click(function () {
    $('#' + feed_item_id + ' #pspacing').attr('class', 'pspacing posts')
    $('#' + feed_item_id + ' .showmorelink').css({ display: 'none' })
  })
  // Hide "Show More" link if it is clicked and show the remaining post
  $('#' + feed_item_id + ' .showmorelink').click(function () {
    $('#' + feed_item_id + ' .showmorelink').css({ display: 'none' })
    $('#' + feed_item_id + ' #pspacing').attr('class', 'pspacing posts')
  })
  // Trigger popover on click
  $('#copylink' + feed_item_id).click(function () {
    $('#popover' + feed_item_id).click()
    $('html').removeClass('modal-open')
  })

  // Initialize popover
  $(function () {
    $('#popover' + feed_item_id).popover()
  })
}

window.feed.addListeners = (feed_item_id) => addListeners(feed_item_id)

window.feed.showExpertComments = function (id) {
  $(`#${id} .comments_section, #${id} .comments-div`).slideDown()
  if (document.querySelector(`#${id} .comments_section textarea`)) {
    $(`#${id} .comments_section textarea`).focus()
    autosize($(`#${id} .comments_section textarea`))
  }
}

$(document).ready(function () {
  autosize($('textarea.newcomment'))
  $('.feed_item').each(function () {
    addListeners(this.id)
  })
})

$(document).ready(() =>
  $('.text-body').each(function (index, value) {
    const truncate_element = $(value).find('.truncated-text')
    if (truncate_element.length > 0) {
      truncate_element.text('...')
      truncate_element.next().addClass('truncated-text-hide')
      truncate_element.parent().nextAll('p').addClass('truncated-text-hide')
      truncate_element.parent().css('display', 'inline')
      const link = $("<a href='#'>View More</a>")
        .addClass('truncated-text')
        .click(function (e) {
          truncate_element.parent().css('display', '')
          const textDiv = $(this).parent()
          truncate_element.find('p').css('display', 'block')
          textDiv.find('.truncated-text').remove()
          textDiv.find('.truncated-text-hide').removeClass('truncated-text-hide')
          textDiv.find('p:empty').remove()
          return e.preventDefault()
        })
      return $(value).append(link)
    }
  })
)
