import './panel-video-player-supplemental-video-overlay.css'

const containerSelector = '.c-panel-video-player-supplemental-video-overlay'
const videoPlayerSelector = '.c-panel-video-player'

document.addEventListener('DOMContentLoaded', function () {
  const videoContainer = document.querySelector(videoPlayerSelector)
  if (videoContainer === null) return
  const container = document.querySelector(containerSelector)
  if (container === null) return

  videoContainer.appendChild(container)
})
