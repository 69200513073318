import './class-review-prompt.css'
import Cookies from 'universal-cookie'

const BASE_SELECTOR = '.c-class-review-prompt'
const MAYBE_LATER_BUTTON_SELECTOR = `${BASE_SELECTOR}__btn-later-js`
const WORKSHOP_ID_INPUT_SELECTOR = `${BASE_SELECTOR} input[name='workshop_review[workshop_id]']`
const COOKIE_PREFIX = 'class-review-prompt'
const cookies = new Cookies()
const cookieVal = 'hide'

$(() => {
  const container = $(BASE_SELECTOR)
  if (!container) return
  const maybeLaterButton = $(MAYBE_LATER_BUTTON_SELECTOR)
  maybeLaterButton.on('click', () => {
    const classId = $(WORKSHOP_ID_INPUT_SELECTOR).val()
    $(BASE_SELECTOR).hide()
    setDisableCookie(classId, 30)
  })
})

function setDisableCookie(classId, minutes) {
  let expires = new Date()
  expires.setTime(expires.getTime() + minutes * 60 * 1000)
  cookies.set(`${COOKIE_PREFIX}-${classId}`, cookieVal, {
    path: '/',
    expires,
  })
}

export function isClassReviewPromptDisabled(classId) {
  return cookies.get(`${COOKIE_PREFIX}-${classId}`) === cookieVal
}
