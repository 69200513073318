import './program-section-overview.css'

$(() => {
  const potentialOrangeTexts = '.c-program-section-overview .orange-text a'
  $(potentialOrangeTexts).on('click', function (event) {
    if (this.hash !== '') {
      event.preventDefault()
      var hash = this.hash
      $('html, body').animate(
        {
          scrollTop: $(hash).offset().top,
        },
        400,
        function () {
          window.location.hash = hash
        }
      )
    }
  })
})
