import { CourseLesson } from './lesson'

export class HandoutLesson extends CourseLesson {
  imageUrl: string
  pdfUrl: string

  constructor(lessonEl: HTMLDivElement) {
    super(lessonEl)
    this.imageUrl = this.htmlEl.dataset.previewImageUrl ?? ''
    this.pdfUrl = this.htmlEl.dataset.pdfUrl ?? ''
  }
}
