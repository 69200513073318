export class Pagination {
  constructor(containerSelector, list, pageSize) {
    this.containerSelector = containerSelector
    this.currentPage = 0
    this.list = list
    this.addClickListener()
    this.cardPageSize = pageSize
  }

  cardPageSize() {
    return this.cardPageSize
  }

  addClickListener() {
    const instance = this
    document.querySelector(`${this.containerSelector}__js-load-more`).addEventListener('click', function () {
      instance.loadNextPage()
    })
  }

  resetPagination() {
    this.currentPage = 0
    this.displayLoadMoreCheck(this.list.matchingItems.length)
  }

  loadNextPage() {
    this.currentPage += 1
    const baseIndex = this.currentPage * this.cardPageSize
    this.showNextPage(this.list.matchingItems.slice(baseIndex, baseIndex + this.cardPageSize))
    this.displayLoadMoreCheck(this.list.matchingItems.length)
  }

  showNextPage(items) {
    for (let i = 0; i < items.length; i++) {
      items[i].show()
    }
  }

  displayLoadMoreCheck(matchedItemLength) {
    const button = document.querySelector(`${this.containerSelector}__js-load-more`)
    if (matchedItemLength <= this.currentPage * this.cardPageSize + this.cardPageSize) {
      button.classList.add('hide')
    } else {
      button.classList.remove('hide')
    }
  }
}
