export default function getOffsetTop(elem) {
  // Set our distance placeholder
  let distance = 0

  // Loop up the DOM
  if (elem.offsetParent) {
    do {
      distance += elem.offsetTop
      elem = elem.offsetParent
    } while (elem)
  }

  // Return our distance
  return distance < 0 ? 0 : distance
}
