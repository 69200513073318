import './amazon-pay-form.css'

// Only one off payments have been tested and implemented
// Subscription code is copied from old javascript, needs testing
// This probably should be split into different recurring & one off components
// Relies on jQuery

const selector = '.c-amazon-pay-form'

export default function amazonPayForm() {
  amazon.Login.setClientId('amzn1.application-oa2-client.b6408583275d437a81b101dd0ee2797d')
  const components = document.querySelectorAll(selector)
  for (let i = 0; i < components.length; i++) {
    createCard(components[i].getAttribute('id'))
  }
}

// https://developer.amazon.com/docs/amazon-pay-onetime/no-address-widget.html
function createCard(componentId) {
  const designMode = isSmartphone() ? 'smartphoneCollapsible' : 'responsive'
  var orderReferenceId = null
  var billingAgreementId = gon.billingAgreementId
  var consentGiven = false

  createWallet(componentId, designMode)
  handleFormSubmit(componentId)

  function handleFormSubmit(componentId) {
    const form = document.querySelector(`#${componentId} ${selector}__form`)
    form.addEventListener('submit', function (event) {
      const price = document.querySelector(`#${componentId} ${selector}__price`).value
      if (price === 0) return form.submit()
      if (gon.agreementType === 'orderReference') {
        if (!handleOrderReference(componentId)) event.preventDefault()
      } else if (gon.agreementType) {
        if (!handleGonSubscription(componentId, consentGiven)) event.preventDefault()
      } else {
        if (!handleFallbackSubscription(componentId, consentGiven, billingAgreementId)) event.preventDefault()
      }
    })
  }

  function createWallet(componentId, designMode) {
    // Display the Amazon Wallet Widget
    new OffAmazonPayments.Widgets.Wallet({
      sellerId: gon.amazon_merchant_id,
      onReady: function (billingAgreement) {
        billingAgreementId = billingAgreement.getAmazonBillingAgreementId()
        const billingAgreementInput = document.getElementById('billing_agreement_id')
        if (billingAgreementInput) billingAgreementInput.setAttribute('value', billingAgreementId)
      },
      agreementType: gon.agreementType || 'BillingAgreement',
      design: {
        designMode: designMode,
      },
      onPaymentSelect: function (orderReferenceId) {
        if (gon.agreementType === 'orderReference') {
          enableButton(componentId)
        } else {
          displayConsentWidget(componentId, orderReferenceId)
        }
      },
      onError: function (error) {
        amazon.Login.logout()

        if (error.getErrorCode() === 'BuyerSessionExpired') {
          window.location.href = gon.error_url
        }
      },
      amazonBillingAgreementId: !!gon.billingAgreementId ? gon.billingAgreementId : null,
      amazonOrderReferenceId: !!gon.order_reference_id ? gon.order_reference_id : null,
      onOrderReferenceCreate: !!gon.order_reference_id
        ? null
        : function (orderReference) {
            orderReferenceId = orderReference.getAmazonOrderReferenceId()
            const input = document.getElementById('order_reference_id')
            if (input) input.setAttribute('value', orderReferenceId)
          },
    }).bind(document.querySelector(`#${componentId} ${selector}__wallet-widget`).getAttribute('id'))
  }

  // Display the Amazon Recurring Payments Consent Widget
  // Only required for recurring payments
  function displayConsentWidget(componentId, orderReferenceId) {
    const consentWidgetId = document.querySelector(`#${componentId} .consent-widget`).attr('id')
    new OffAmazonPayments.Widgets.Consent({
      sellerId: gon.amazon_merchant_id,
      // amazonBillingAgreementId obtained from the Amazon Address Book widget.
      amazonOrderReferenceId: orderReferenceId,
      design: {
        designMode: 'responsive',
      },
      onReady: function (billingAgreementConsentStatus) {
        buyerBillingAgreementConsentStatus = billingAgreementConsentStatus.getConsentStatus()
      },
      onConsent: function (billingAgreementConsentStatus) {
        enableButton(componentId)
        consentStatus = billingAgreementConsentStatus.getConsentStatus()
        consentGiven = consentStatus === 'true'
      },
      onError: function (error) {
        enableButton(componentId)
      },
    }).bind(consentWidgetId)
  }
}

function enableButton(componentId) {
  document.querySelector(`#${componentId} input[type=submit]`).disabled = false
}

function disableButton(componentId) {
  document.querySelector(`#${componentId} input[type=submit]`).disabled = true
  $('*').css('cursor', 'progress')
}

function handleOrderReference(componentId) {
  disableButton(componentId)
  return true
}

function handleGonSubscription(componentId, consentGiven) {
  if (consentGiven) {
    disableButton(componentId)
    return true
  } else {
    consentAlert(componentId)
    return false
  }
}

function handleFallbackSubscription(componentId, consentGiven, billingAgreementId) {
  if (consentGiven) {
    disableButton(componentId)
    remoteSubmitSubscription(componentId, billingAgreementId)
    return false
  } else {
    consentAlert(componentId)
    return false
  }
}

function remoteSubmitSubscription(componentId, billingAgreementId) {
  fetch('/payment/subscribe/create_through_amazon', {
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    method: 'POST',
    body: JSON.stringify(remoteSubmitSubscriptionData(billingAgreementId)),
  })
    .then((response) => response.json())
    .then((data) => {
      $('*').css('cursor', 'default')
      if (data.success) window.location = data.redirect_path
      else showGenericAlert(componentId)
    })
    .catch((error) => {
      $('*').css('cursor', 'default')
      showGenericAlert(componentId)
    })
}

function consentAlert(componentId) {
  // TODO change from id
  $(`#${componentId} .consent-widget`).css('border', '2px solid red').css('border-radius', '7px')
}

function showGenericAlert(componentId) {
  // TODO change from id
  $('#amazon-confirm-alert').html(
    '<div class="alert alert-danger" role="alert">There was an issue processing your payment. Please reload the page and try again.</div>'
  )
}

function remoteSubmitSubscriptionData(billingAgreementId) {
  return {
    service: 'amazon',
    billing_agreement_id: billingAgreementId,
    referral_controller: gon.controller,
    referral_action: gon.action,
    type: 'old-ajax-window',
  }
}

function isSmartphone() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
