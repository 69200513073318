import { queryElement } from '../querySelectorHelpers'

const CLASS_NAME_PREFIX = 'c-class-review-modal'
const BASE_SELECTOR = `.${CLASS_NAME_PREFIX}`
const WORKSHOP_ID_INPUT_SELECTOR = "input[name='workshop_review[workshop_id]']"
const WORKSHOP_TYPE_INPUT_SELECTOR = "input[name='workshop_review[workshop_type]']"

export class ReviewModal {
  private reviewModalElement: HTMLDivElement

  constructor(reviewModalElement: HTMLDivElement) {
    this.reviewModalElement = reviewModalElement
  }

  setWorkshop(workshopId: string, workshopType: string) {
    const workshopIdField = this.getWorkshopIdFieldEl()
    const workshopTypeField = this.getWorkshopTypeFieldEl()
    workshopIdField.value = workshopId
    workshopTypeField.value = workshopType
  }

  private getWorkshopIdFieldEl() {
    return queryElement(this.reviewModalElement, HTMLInputElement, WORKSHOP_ID_INPUT_SELECTOR)
  }

  private getWorkshopTypeFieldEl() {
    return queryElement(this.reviewModalElement, HTMLInputElement, WORKSHOP_TYPE_INPUT_SELECTOR)
  }
}
