import { Workshop } from '../workshops/workshop'
import { Lesson } from './lesson'
import { LessonsIterator } from './lessons-iterator'

export class LessonsCollection {
  private workshops: Workshop[] = []
  private lessons: Lesson[] = []

  public getWorkshops(): Workshop[] {
    return this.workshops
  }

  public getWorkshopsCount(): number {
    return this.lessons.length
  }

  public getAllLessons(): Lesson[] {
    return this.workshops.flatMap((w) => w.lessons)
  }

  public addWorkshop(workshop: Workshop): void {
    this.workshops.push(workshop)
  }

  public getLessonsIterator() {
    return new LessonsIterator(this)
  }
}
