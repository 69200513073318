/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

$(function () {
  if (!$('.reviews.topics .js-single-paginator').length) {
    return
  }
  $('.reviews.topics .js-single-paginator').each(function () {
    const $paginator = $(this)
    setInterval(function () {
      let $next = $paginator.children('.selected').next()
      if (!$next.length) {
        $next = $paginator.children().first()
      }
      singlePaginator($next)
    }, 4000)
  })

  $('.reviews.topics .js-single-paginator button').click(function () {
    if (!$(this).hasClass('selected')) {
      singlePaginator($(this))
    }
  })

  var singlePaginator = function ($btn) {
    const $container = $btn.parent().parent('.js-footer').siblings('.js-wrapper')
    const targetPage = parseInt($btn.data('page'))
    // include margin in width of box + 3 for space in between sections
    const xDiff = -1 * targetPage * $container.find('li').outerWidth(true)
    $container.find('li').css('transform', `translate(${parseInt(xDiff)}px, 0)`)
    $btn.siblings('.selected').removeClass('selected')
    $btn.addClass('selected')
  }
})
