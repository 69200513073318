import './email-confirm-modal.css'

// Pass in a function to be called when the user taps "Skip"
export function emailConfirmShowModal(container, onConfirm) {
  const modal = container.querySelector('.email-confirm-modal')
  const confirmButton = container.querySelector('.email-confirm-modal-continue-button')

  if (!modal) return
  if (!confirmButton) return

  $(confirmButton).unbind()
  $(confirmButton).click(function () {
    emailConfirmDismissModal(container)
    onConfirm()
  })

  modal.classList.toggle('email-confirm-modal-show-modal')
}

export function emailConfirmDismissModal(container) {
  const modal = container.querySelector('.email-confirm-modal')
  if (!modal) return

  modal.classList.remove('email-confirm-modal-show-modal')
}

export function emailConfirmSetUp(container) {
  const modal = container.querySelector('.email-confirm-modal')
  const closeButton = container.querySelector('.email-confirm-modal-close-button')

  if (!modal) return
  if (!closeButton) return

  $(closeButton).click(function () {
    emailConfirmDismissModal(container)
  })

  $(window).click(function (event) {
    const modal = container.querySelector('.email-confirm-modal')
    if (!modal) return

    if (event.target === modal) {
      emailConfirmDismissModal(container)
    }
  })
}
