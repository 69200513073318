import './panel-workshop-list.css'
import { PanelWorkshopListHtml } from './helpers/panel-workshop-list-html'
import { VideoPlayer } from '@/utils/course-view/video-player'
import { LessonsCollection } from '@/utils/course-view/lessons/lessons-collection'
import { LessonCreator } from '@/utils/course-view/lessons/lesson-creator'
import { Lesson } from '@/utils/course-view/lessons/lesson'
import { Workshop } from '@/utils/course-view/workshops/workshop'
import { VideoPlayerUpdater } from '@/utils/course-view/video-player-updater'
import { HandoutOverlayUpdater } from '@/utils/course-view/handout-overlay-updater'
import { HandoutOverlay } from '@/utils/course-view/overlays/handout-overlay'
import { SupplementalVideoOverlay } from '@/utils/course-view/overlays/supplemental-video-overlay'
import { SupplementalVideoOverlayUpdater } from '@/utils/course-view/supplemental-video-overlay-updater'
import { CourseView } from '@/utils/course-view/course-view'
import { ReviewPrompt } from '@/utils/course-view/review-prompt'
import { ReviewPromptUpdater } from '@/utils/course-view/review-prompt-updater'

document.addEventListener('DOMContentLoaded', () => {
  const panelContainer = PanelWorkshopListHtml.getContainerElement()

  if (!gon.current_user_id || !panelContainer) {
    return
  }

  const workshopEls = PanelWorkshopListHtml.getWorkshopElements(panelContainer)
  const lessonsCollection = createLessonsCollection(workshopEls)

  const { playerUpdater, handoutOverlayUpdater, suppVideoOverlayUpdater, reviewPromptUpdater } =
    createCourseViewUpdaters()

  new CourseView(lessonsCollection, playerUpdater, handoutOverlayUpdater, suppVideoOverlayUpdater, reviewPromptUpdater)
})

function createVideoPlayer() {
  return new VideoPlayer({
    videoJsPlayer: videojs(PanelWorkshopListHtml.getVideoPlayerElement()),
    muxEnvKey: gon.muxEnvKey,
    userId: gon.userId,
    doNotTrack: PanelWorkshopListHtml.getIsTinyhoodUser() || !gon.trackMux,
  })
}

function createLessonsCollection(workshopEls: HTMLDivElement[]) {
  const lessonsCollection = new LessonsCollection()

  workshopEls.forEach((workshopEl) => {
    const stepEls = PanelWorkshopListHtml.getStepElements(workshopEl)
    const lessons = stepEls
      .map((stepEl) => LessonCreator.createLesson(stepEl))
      .filter((lesson): lesson is Lesson => typeof lesson === 'object')

    const workshop = new Workshop({ workshopEl, lessons })

    lessonsCollection.addWorkshop(workshop)
  })

  return lessonsCollection
}

function createCourseViewUpdaters() {
  const handoutOverlayEl = PanelWorkshopListHtml.getHandoutOverlayElement()
  const suppVideoOverlayEl = PanelWorkshopListHtml.getSupplementalVideoOverlayElement()
  const reviewPromptEl = PanelWorkshopListHtml.getReviewPromptElement()

  const player = createVideoPlayer()
  const handoutOverlay = new HandoutOverlay(handoutOverlayEl)
  const suppVideoOverlay = new SupplementalVideoOverlay(suppVideoOverlayEl)
  const reviewPrompt = new ReviewPrompt(reviewPromptEl)

  const playerUpdater = new VideoPlayerUpdater(player)
  const handoutOverlayUpdater = new HandoutOverlayUpdater(handoutOverlay)
  const suppVideoOverlayUpdater = new SupplementalVideoOverlayUpdater(suppVideoOverlay, player)
  const reviewPromptUpdater = new ReviewPromptUpdater(reviewPrompt)

  return {
    playerUpdater,
    handoutOverlayUpdater,
    suppVideoOverlayUpdater,
    reviewPromptUpdater,
  }
}
