import './header-signed-in-v1.css'
import { closeHamburgerMenu } from 'components/hamburger-button/hamburger-button.js'

/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  const selector = '.header-signed-in-v1'
  if (document.querySelector(selector) === null) return

  /* note bootsrap 2.3.2 does not trigger events for hide dropdown toggles */
  $('.header-signed-in-v1 .header-dropdown .dropdown-toggle').click(function () {
    const $parent = $(this).parents('.header-dropdown')
    if (!$('.header-dropdown.open').is($parent)) {
      /* close other headers */
      closeHeaderMenu($('.header-dropdown.open'))
    }
    if ($parent.hasClass('open')) {
      closeHeaderMenu($parent)
    } else {
      openHeaderMenu($parent)
    }
  })

  function openHeaderMenu($dropdownContainer) {
    $('html').addClass('modal-open')
    if (screen.width > 550) $('html').css('overflow-y', 'scroll')
    $dropdownContainer.addClass('open')
    $dropdownContainer.find('.dropdown-menu').trigger('show.headerMenu')
    $dropdownContainer.find('.notifcircleicon').css('display', 'none')
  }

  function closeHeaderMenu($dropdownContainer) {
    $('html').removeClass('modal-open')
    $dropdownContainer.find('.dropdown-menu').trigger('hide.headerMenu')
    return $dropdownContainer.removeClass('open')
  }

  $(document).keyup(function (e) {
    if (e.keyCode === 27) {
      /* escape key */
      if ($('.c-hamburger-button__container').hasClass('in')) {
        closeHamburgerMenu()
      } else if ($('.header-dropdown.open').length) {
        closeHeaderMenu($('.header-dropdown.open'))
      }
    }
  })
})
