import Cookies from 'universal-cookie'
const cookies = new Cookies()

$(function () {
  // for the package home page
  // TODO ^ is probably deprecated - investigate and remove
  if ($('.home.index .expert-session.boxes.workshops').length) {
    if ($('.show-more-workshops-link').css('display') !== 'none') {
      const $sessions = $('.session-box')
      if ($sessions.length <= 4) {
        $('.show-more-workshops-link').remove()
      } else {
        $('.workshops.boxes li').slice(2).css('display', 'none')
        $('.show-more-workshops-link').click(function () {
          $('.workshops.boxes li').slice(2).css('display', '')
          $(this).remove()
          return false
        })
      }
    }
  }
})

$(function () {
  $('.boxes.auto-paginate').each(function () {
    const $paginator = $(this).find('.box-paginator')
    $paginator.data('rotate', true)
    setInterval(function () {
      if (!$paginator.data('rotate')) {
        return false
      }
      let $next = $paginator.children('.selected').next()
      if (!$next.length) {
        $next = $paginator.children().first()
      }
      scrollBoxPaginator($next)
    }, parseInt($(this).data('time')))
  })

  $('.box-paginator button').click(function () {
    $(this).parent().data('rotate', false)
    if (!$(this).hasClass('selected')) {
      scrollBoxPaginator($(this))
    }
  })

  var scrollBoxPaginator = function ($btn) {
    const $boxes = $btn.parents('.footer').siblings('ul')
    const targetPage = parseInt($btn.data('page'))
    const columnsPerPage = parseInt($btn.parent().data('per-page'))
    // include margin in width of box + 3 for space in between sections
    const pageWidth = $boxes.children().outerWidth(true) + 3.5
    const xDiff = -1 * columnsPerPage * targetPage * pageWidth
    $boxes.children().css('transform', `translate(${parseInt(xDiff)}px, 0)`)
    $btn.siblings('.selected').removeClass('selected')
    $btn.addClass('selected')
  }
})
