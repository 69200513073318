const TIME_INCREMENT = 15

const CONTAINER_SELECTOR = '.bcove_embed'

document.addEventListener('DOMContentLoaded', function () {
  const container = document.querySelector(CONTAINER_SELECTOR)
  if (!container) return

  videojs.getPlayer(document.querySelector(`${CONTAINER_SELECTOR} video-js`)).ready(function () {
    let player = this
    const controlBar = document.querySelector('.vjs-control-bar')
    const insertionPoint = document.querySelector('.vjs-volume-panel')

    var incrementBackBtn = document.createElement('div')
    var incrementForwardBtn = document.createElement('div')

    incrementBackBtn.className = 'time-control time-control--increment-back'
    incrementForwardBtn.className = 'time-control time-control--increment-forward'

    controlBar.insertBefore(incrementBackBtn, insertionPoint)
    controlBar.insertBefore(incrementForwardBtn, insertionPoint)

    incrementBackBtn.addEventListener('click', function () {
      player.currentTime(incrementPlayerBack(player))
    })

    incrementForwardBtn.addEventListener('click', function () {
      player.currentTime(incrementPlayerForward(player))
    })
  })
})

function incrementPlayerBack(player) {
  const videoTimeIndex = Math.round(player.currentTime() - TIME_INCREMENT)
  if (videoTimeIndex < 0) {
    return 0
  } else {
    return videoTimeIndex
  }
}

function incrementPlayerForward(player) {
  const videoTimeIndex = Math.round(player.currentTime() + TIME_INCREMENT)
  const videoDuration = player.duration()
  if (videoTimeIndex > videoDuration) {
    return videoDuration
  } else {
    return videoTimeIndex
  }
}
