$(function () {
  if ($('.sessions.new').length) {
    if ($('.sign-up-form input[type="email"]').val().length > 0) {
      $('.js-log-in-container').removeClass('selected')
      $('.js-sign-up-container').addClass('selected')
      $('.js-log-in').parent().removeClass('selected')
      $('.js-sign-up').parent().addClass('selected')
    }

    $('.js-log-in').click(function () {
      $(this).parent().siblings().removeClass('selected')
      $(this).parent().addClass('selected')
      $('.js-sign-up-container').removeClass('selected')
      $('.js-log-in-container').addClass('selected')
      $('.js-log-in-container input[type!="hidden"]')[0].focus()
    })
    $('.js-sign-up').click(function () {
      $(this).parent().siblings().removeClass('selected')
      $(this).parent().addClass('selected')
      $('.js-log-in-container').removeClass('selected')
      $('.js-sign-up-container').addClass('selected')
      $('.js-sign-up-container input[type!="hidden"]')[0].focus()
    })
    if ($('.focus-registration').length) {
      $('.js-sign-up').click()
    }
  }
})

$(function () {
  if ($('.blue-background-flow').length && $(window).width() < 550) {
    $('body').addClass('hide-header hide-footer').css('padding', 0)
    $('#header, .th-footer').remove()
  }

  if ($('.topic-selections.new').length) {
    $('.topic-selections.new form input[type="submit"]').on('click', function () {
      const $requiredCheckboxes = $('.topic-selections.new form input:checkbox')
      if ($requiredCheckboxes.is(':checked')) {
        $requiredCheckboxes.removeAttr('required')
      } else {
        $requiredCheckboxes.attr('required')
        const html = "<div class='error'>You must select at least one topic.</div>"
        $('.topic-selections.new .body').prepend(html)
      }
    })
  }
  if ($('.sign_up.kids.new')) {
    if ($('#kid_birthdate').prop('type') === 'date') {
      $('#kid_birthdate').css('display', 'block')
      $('.birthday-select').remove()
    }
  }
})

// This is for previewing an attached image
const readURLGenericSignUp = function (input) {
  if (input.files && input.files[0]) {
    const reader = new FileReader()

    reader.onload = function (e) {
      $('#user_image_preview').attr('src', e.target.result)
      $('#original_user_image').css({ display: 'none' })
      $('#user_image_preview').css({ display: 'block' })
    }

    reader.readAsDataURL(input.files[0])
  }
}

$(function () {
  if ($('.sign_up.photos.new').length) {
    // if the user has already uploaded an image let them go to the next page
    if (!$('.sign_up.photos.new .image-div #original_user_image').prop('src').includes('profile_default')) {
      $('.sign_up.photos.new input[type="submit"]').removeAttr('disabled')
    }
    $('#user_image').change(function () {
      readURLGenericSignUp(this)
      $('.sign_up.photos.new input[type="submit"]').removeAttr('disabled')
    })
  }
})
