import { queryElement } from '../../querySelectorHelpers'

const CLASS_NAME_PREFIX = 'c-panel-video-player-handout-overlay'
const ACTIVE_CLASS_NAME = `${CLASS_NAME_PREFIX}--active`
const INITIAL_SELECTOR = `.${CLASS_NAME_PREFIX}`
const TITLE_SELECTOR = `${INITIAL_SELECTOR}__title`
const IMAGE_SELECTOR = `${INITIAL_SELECTOR}__image`
const BUTTON_SELECTOR = `${INITIAL_SELECTOR}__btn`

export class HandoutOverlay {
  private handoutOverlayElement: HTMLDivElement
  private nextLessonLink: HTMLAnchorElement
  private nextLessonAnimationWrapper: HTMLDivElement
  private nextLessonCallbackFn?: () => void
  private autoplayTimer: number
  private shouldAutoplayNext: boolean

  constructor(handoutOverlayElement: HTMLDivElement) {
    this.handoutOverlayElement = handoutOverlayElement
    this.nextLessonLink = queryElement(handoutOverlayElement, HTMLAnchorElement, `${INITIAL_SELECTOR}__next-lesson`)
    this.nextLessonAnimationWrapper = queryElement(
      handoutOverlayElement,
      HTMLDivElement,
      `${INITIAL_SELECTOR}__progress-wrapper`
    )

    this.getButtonEl().addEventListener('click', () => {
      this.cancelAutoplayCountdown()
    })
    this.nextLessonLink.addEventListener('click', () => {
      if (this.nextLessonCallbackFn) this.nextLessonCallbackFn()
    })

    this.shouldAutoplayNext = false
    this.autoplayTimer = 0
  }

  show(title: string, imageUrl: string, handoutUrl: string, autoplay: boolean, nextLessonCallbackFn?: () => void) {
    this.shouldAutoplayNext = autoplay

    this.handoutOverlayElement.classList.add(ACTIVE_CLASS_NAME)
    this.nextLessonCallbackFn = nextLessonCallbackFn
    this.getTitleEl().innerHTML = title
    this.getImageEl().style.backgroundImage = `url(${imageUrl})`
    this.getButtonEl().setAttribute('href', handoutUrl ?? '')

    // remove existing progress animation & clear timer
    this.cancelAutoplayCountdown()

    if (this.nextLessonCallbackFn === undefined) {
      this.nextLessonLink.classList.add('hide')
    } else {
      this.nextLessonLink.classList.remove('hide')

      if (this.shouldAutoplayNext) {
        this.nextLessonAnimationWrapper.classList.add('progress-animation')

        // NOTE: timeout must match CSS used in `c-panel-video-player-handout-overlay`
        this.autoplayTimer = window.setTimeout(() => {
          if (this.nextLessonCallbackFn) this.nextLessonCallbackFn()
        }, 5500)
      }
    }
  }

  hide() {
    if (this.nextLessonCallbackFn) {
      this.nextLessonLink.removeEventListener('click', this.nextLessonCallbackFn)
    }
    this.handoutOverlayElement.classList.remove(ACTIVE_CLASS_NAME)
    this.cancelAutoplayCountdown()
  }

  private getTitleEl() {
    return queryElement(this.handoutOverlayElement, HTMLSpanElement, TITLE_SELECTOR)
  }

  private getImageEl() {
    return queryElement(this.handoutOverlayElement, HTMLDivElement, IMAGE_SELECTOR)
  }

  private getButtonEl() {
    return queryElement(this.handoutOverlayElement, HTMLAnchorElement, BUTTON_SELECTOR)
  }

  private cancelAutoplayCountdown() {
    window.clearTimeout(this.autoplayTimer)
    this.nextLessonAnimationWrapper.classList.remove('progress-animation')
    this.nextLessonAnimationWrapper.offsetHeight // needed to trigger a reflow
    this.autoplayTimer = 0
  }
}
