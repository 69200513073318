import './brightcove-modal-dialog.css'

export class BrightcoveModalDialog {
  constructor(player, contentSelector) {
    this.player = player
    this.contentSelector = contentSelector
    this.initializeOverlay()
  }

  initializeOverlay() {
    const ModalDialog = videojs.getComponent('ModalDialog')
    const contentElem = document.querySelector(this.contentSelector)
    let modalOptions = JSON.parse(contentElem.dataset.modalOptions)
    modalOptions.content = contentElem
    this.modal = new ModalDialog(this.player, modalOptions)
    this.modal.addClass('c-brightcove-modal-dialog--parent')
    this.player.addChild(this.modal)
  }
}
