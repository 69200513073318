$(function () {
  // This is for previewing an attached image AND allowing user to select images again
  if (!$('#new_system_post').length) {
    return
  }
  const readURLSystemPost = function (input) {
    $('.file_container').append(
      '<input accept="image/jpeg, image/jpg, image/gif, image/png" class="postimages" multiple="multiple" name="system_post[images][]" onchange="readURL(this); " type="file"/>'
    )
    if (input.files && input.files[0]) {
      $(input.files).each(function () {
        const reader = new FileReader()
        reader.readAsDataURL(this)

        reader.onload = function (e) {
          $('.post_file_image_preview_div').css({ display: 'block' })
          $('.post_file_image_preview_div').append(
            "<img class='post_file_image_preview' src='" + e.target.result + "'>"
          )
        }
      })
    }
  }
  $('.postimages').change(function () {
    readURLSystemPost(this)
  })
})

$(function () {
  updateSystemPostSelect($('#system_post_type').val())
  $('#system_post_type').change(function () {
    updateSystemPostSelect($(this).val())
    return $('#target_items_select').val('')
  })

  if ($('#system_post_link_title').val() && $('#system_post_link_title').val().length) {
    $('#link-preview-div').css('display', 'block')
  }
})

var updateSystemPostSelect = function (type) {
  switch (type) {
    case 'SystemPromo':
      $('.js-age-groups').css('display', 'block')
      $('#target_items_select').parent().css('display', 'none')
      show(['.web_url', '.app_path', '.show-link-preview'])
      $('label[for="system_post_button_text"]').removeClass('required')
      $('label[for="system_post_target_item_id"]').removeClass('required')
      break
    default:
      $('#target_items_select').parent().css('display', 'none')
  }
}

var filterSelect = function (selectSelector, filterSelector) {
  $(selectSelector).css('display', 'block')
  hide([`${selectSelector} option`])
  $(`${selectSelector} ${filterSelector}`).css('display', 'block')
}

var hide = (selectors) => Array.from(selectors).map((selector) => $(selector).css('display', 'none'))

var show = (selectors) => Array.from(selectors).map((selector) => $(selector).css('display', 'block'))
