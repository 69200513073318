import './sticky-bottom-section.css'
import getOffsetTop from 'utils/getOffsetTop.js'

const noTopElemSelector = 'c-sticky-bottom-section--no-top-elem-js'

$(() => {
  const stickyPayment = document.querySelector('.c-sticky-bottom-section')
  if (!stickyPayment) return

  const displayHeight = getDisplayHeight(stickyPayment)
  evalStickyScroll(stickyPayment, displayHeight)
  stickyPayment.classList.remove('hide')
  window.addEventListener('scroll', function () {
    evalStickyScroll(stickyPayment, displayHeight)
  })
})

const getDisplayHeight = (stickyPayment) => {
  const topElem = document.querySelector(
    '.c-landing-simple-hero .btn-cta, .c-landing-hero-video .btn-cta, .c-landing-hero__content-container'
  )
  if (stickyPayment.classList.contains(noTopElemSelector)) {
    return 0
  } else {
    return getOffsetTop(topElem) + topElem.clientHeight
  }
}

const evalStickyScroll = (elem, cutoff) => {
  if (window.scrollY >= cutoff) {
    if (elem.classList.contains('hide-opacity')) {
      elem.classList.remove('hide-opacity')
    }
  } else {
    if (!elem.classList.contains('hide-opacity')) {
      elem.classList.add('hide-opacity')
    }
  }
}
