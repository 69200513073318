import { queryElement } from '../querySelectorHelpers'
import { ReviewModal } from './review-modal'

const CLASS_NAME_PREFIX = 'c-class-review-prompt'
const BASE_SELECTOR = `.${CLASS_NAME_PREFIX}`
const WORKSHOP_ID_INPUT_SELECTOR = "input[name='workshop_review[workshop_id]']"
const WORKSHOP_TYPE_INPUT_SELECTOR = "input[name='workshop_review[workshop_type]']"

export class ReviewPrompt {
  private reviewPromptElement: HTMLDivElement
  private reviewModalElement: HTMLDivElement
  private reviewModal: ReviewModal

  constructor(reviewPromptElement: HTMLDivElement) {
    this.reviewPromptElement = reviewPromptElement
    this.reviewModalElement = queryElement(document, HTMLDivElement, '.c-class-review-modal')
    this.reviewModal = new ReviewModal(this.reviewModalElement)
  }

  setWorkshop(workshopId: string, workshopType: string) {
    const workshopIdField = this.getWorkshopIdFieldEl()
    const workshopTypeField = this.getWorkshopTypeFieldEl()
    workshopIdField.value = workshopId
    workshopTypeField.value = workshopType
    this.reviewModal.setWorkshop(workshopId, workshopType)
  }

  show() {
    queryElement(document, HTMLDivElement, BASE_SELECTOR).style.display = 'flex'
  }

  hide() {
    queryElement(document, HTMLDivElement, BASE_SELECTOR).style.display = 'none'
  }

  private getWorkshopIdFieldEl() {
    return queryElement(this.reviewPromptElement, HTMLInputElement, WORKSHOP_ID_INPUT_SELECTOR)
  }

  private getWorkshopTypeFieldEl() {
    return queryElement(this.reviewPromptElement, HTMLInputElement, WORKSHOP_TYPE_INPUT_SELECTOR)
  }
}
