import { Lesson } from '../lessons/lesson'

export function getMoreRecentlyUpdatedLesson(lessonA: Lesson, lessonB: Lesson): Lesson {
  if (!lessonA?.progressUpdatedAt) {
    return lessonB
  }
  if (!lessonB?.progressUpdatedAt) {
    return lessonA
  }
  return lessonA.progressUpdatedAt > lessonB.progressUpdatedAt ? lessonA : lessonB
}
