// Support for Fast checkout with Apple Pay button.
//
// Docs:
//  https://stripe.com/docs/stripe-js/elements/payment-request-button
//  https://stripe.com/docs/js/payment_request/create
//  https://stripe.com/docs/js/appendix/payment_response

document.addEventListener('DOMContentLoaded', function () {
  const componentName = 'c-applepay-form'
  const forms = document.querySelectorAll(`.js-${componentName}`)
  if (!forms.length) return

  const stripe = Stripe(gon.stripe_publishable_key)
  const elements = stripe.elements()

  var paymentRequest = stripe.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: 'Tinyhood',
      amount: parseInt(document.querySelector('.c-applepay-form__price').value * 100),
    },
    requestPayerName: true,
    requestPayerEmail: true,
    disableWallets: ['googlePay', 'browserCard'],
  })

  var prButton = elements.create('paymentRequestButton', {
    paymentRequest: paymentRequest,
    style: {
      paymentRequestButton: {
        height: '35px',
      },
    },
  })

  // Check the availability of the Payment Request API
  paymentRequest.canMakePayment().then(function (result) {
    if (result) {
      prButton.mount('#applepay-request-button')
    } else {
      document.getElementById('applepay-button-column').style.display = 'none'
    }
  })

  paymentRequest.on('token', async (tokenresponse) => {
    try {
      const orderresponse = await fetch('/checkout/stripe_quick_checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
        },
        body: JSON.stringify({
          order_email: tokenresponse.payerEmail,
          token: tokenresponse.token.id,
          product_id: document.querySelector('.c-applepay-form__product_id').value,
          purchase_object_type: document.querySelector('.c-applepay-form__purchase_object_type').value,
          code: document.querySelector('.c-applepay-form__code').value,
          gift: document.querySelector('.c-applepay-form__gift').value,
        }),
      })

      const responseData = await orderresponse.json()

      if (responseData.status == 'SUCCESS') {
        const payment_path = document.querySelector('.c-applepay-form__paymentpath').value

        tokenresponse.complete('success')
        const baseRedirectUrl = payment_path + '/confirmation?applepay=true&purchase=' + responseData.redemption_id
        const redirectUrl = responseData.gift
          ? `${baseRedirectUrl}&gift=true&gift_id=${responseData.gift_id}`
          : baseRedirectUrl
        window.location.replace(redirectUrl)
      } else {
        tokenresponse.complete('fail')
      }
    } catch (err) {
      tokenresponse.complete('fail')
    }
  })

  prButton.on('click', async () => {
    let new_price = parseInt(document.querySelector('.c-applepay-form__originalprice').value * 100)
    const coupon = document.querySelector('.c-applepay-form__code').value
    if (coupon != null) {
      new_price = parseInt(document.querySelector('.c-applepay-form__price').value * 100)
    }

    paymentRequest.update({
      total: {
        label: 'Tinyhood',
        amount: new_price,
      },
    })
  })
})
