$(function () {
  if (shouldShowMobileSection()) {
    $('#community-header .js-more-link').click(function () {
      $('#community-header .js-mobile-section').toggle('display')
    })
  }
})

var shouldShowMobileSection = () =>
  $('#community-header .js-mobile-section').length && /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
