import { MuxBrightcovePlayerProps, MuxBrightcovePlayer } from '../../muxBrightcovePlayer'
import { SetVideoProps, Video } from './video-player.types'

export type VideoPlayerProps = MuxBrightcovePlayerProps

export class VideoPlayer extends MuxBrightcovePlayer {
  private video?: Video

  constructor(props: VideoPlayerProps) {
    super(props)
  }

  setVideo(props: SetVideoProps) {
    this.video = props.video

    this.changeSrc(props.video.sourceUrl, props.video.sourceType, {
      videoId: props.video.id,
      videoTitle: props.video.title,
    })

    if (props.startAtProgress !== undefined) {
      const { startAtProgress } = props
      this.addListener('loadeddata', () => {
        /* use loadeddata to fix iOS 14 seek controls bug */
        const duration = this.duration()
        const startAtTime = this.getStartAtTime(startAtProgress, duration)
        this.setCurrentTime(startAtTime)
      })
      this.addListener('loadedmetadata', () => {
        if (props.shouldAutoplay) {
          this.play()
        }
      })
    }

    this.addListener('play', () => {
      const duration = this.duration()
      const time = this.currentTime()

      props.onPlay({ duration, time })
    })

    this.addListener('pause', () => {
      const duration = this.duration()
      const time = this.currentTime()

      props.onPause({ duration, time })
    })

    this.addListener('ended', () => {
      const duration = this.duration()
      const time = this.currentTime()

      props.onEnd({ duration, time })
    })

    this.addListener('timeupdate', () => {
      const duration = this.duration()
      const time = this.currentTime()

      props.onProgress({ duration, time })
    })
  }

  private getStartAtTime(progressPercentage: number, duration: number) {
    return progressPercentage ? (progressPercentage / 100.0) * duration : 0
  }
}
