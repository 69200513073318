/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Cookies from 'universal-cookie'
import addFlash from 'utils/addFlash'
const cookies = new Cookies()

const getAnchor = function () {
  if (document.URL.split('#').length > 1) {
    return document.URL.split('#')[1]
  } else {
    return null
  }
}
window.global_getAnchor = getAnchor

$(document).ready(function () {
  if (cookies.get('signed_up')) {
    goog_report_conversion()
  }
  if ($('#modal-container .modal').length) {
    $('#modal-container .modal').modal()
  }
})

$(() =>
  $('.disable-on-click').click(function () {
    return $(this).attr('disabled', true)
  })
)

// show more link -> wrap content in div, then another div.surrounding-show-more
// add a the link below the div.surrounding-show-more with class show-more-link
$(document).ready(function () {
  // Show "Show More" link if needed
  $('.surrounding-show-more').each(function () {
    const actualHeight = $(this).children('div').height()
    const heightLimit = $(this).height()
    if (actualHeight > heightLimit) {
      return $(this).siblings('.show-more-link').css({ display: 'block' })
    }
  })
  // Hide "Show More" link if it is clicked and show the remaining post
  $('.show-more-link').click(function () {
    $(this).css({ display: 'none' })
    $(this).siblings('.surrounding-show-more').addClass('show-all')
    return false
  })

  $('.requesting-approval').submit(function () {
    if (!$(this).hasClass('waiting-approval') && document.getElementById('required-modal')) {
      $(this).addClass('waiting-approval')
      $('#required-modal').on('shown.bs.modal', () => $('#required-modal #user_name').focus())
      $('#required-modal').modal({
        backdrop: 'static',
        keyboard: false,
      })
      return false
    } else {
      return true
    }
  })

  return $('.requesting-name-approval').submit(function () {
    if (!$(this).hasClass('waiting-approval') && document.getElementById('required-name-modal')) {
      $(this).addClass('waiting-approval')
      $('#required-name-modal').on('shown.bs.modal', () => $('#required-name-modal #user_name').focus())
      $('#required-name-modal').modal({
        backdrop: 'static',
        keyboard: false,
      })
      return false
    } else {
      return true
    }
  })
})

$(document).ready(() =>
  $('.toggle_display').change(function () {
    $(`#${$(this).data('toggle-display')}`).toggle()
    return $(`#${$(this).data('toggle-display')} input`).val('')
  })
)

$(document).ready(() => $('.flashmessage').delay(6000).fadeOut(800))
window.globalAddFlash = addFlash /* for Rails js responses */

$(document).ready(function () {
  $('.see-more').click(function (e) {
    $(this).siblings('.hidden').css('display', 'inherit')
    $(this).siblings('.hidden').css('visibility', 'inherit')
    $(this).css('display', 'none')
    e.preventDefault()
  })
})
