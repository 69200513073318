import './post-form--legacy.css'
import autosize from 'autosize'
import { addPostFormSelectGroupListener } from 'components/post-form-select-group/post-form-select-group.js'
import { addPostFormSelectTagListener } from 'components/post-form-select-tag/post-form-select-tag.js'
import { handleUploadedImages } from 'components/post-form-upload-images/post-form-upload-images.js'

document.addEventListener('DOMContentLoaded', function () {
  const selector = '.c-post-form--legacy'
  const postForm = document.querySelector(selector)
  let cancelProgress = false
  if (!postForm) return

  $(`${selector}__imagefield`).change(handleUploadedImages)
  addPostFormSelectGroupListener(postForm.dataset)
  addPostFormSelectTagListener(postForm.dataset)

  /* for previewing attached image AND allowing user to select images again */
  $('#post_isanonymous').click(function () {
    if (this.checked) {
      $('#userimage').css('display', 'none')
    } else {
      $('#userimage').css('display', 'inline')
    }
  })

  $('#newpost').click(function () {
    $('#newpost').css({ 'min-height': '100px' })
    autosize($('#newpost'))
    $('#post_dropdowns').slideDown()
    $('#post_controls').slideDown()
  })

  $('.cancel_post').click(cancelPostListener)

  // Set up jquery-preview: https://github.com/embedly/jquery-preview
  $('#newpost').preview({ key: '57b7101f386f4badad7fe0795e86b7ab' })

  function cancelPostListener() {
    // If confirmation modal was clicked
    if (!$(this).attr('data-confirm')) {
      discardPost()

      setTimeout(() => $('#cancel_post').attr('data-confirm', 'Are you sure you want to discard your post?'), 10)
    }
  }

  function hidePostForm() {
    $('#post_dropdowns').slideUp()
    $('#post_controls').slideUp()
    resetAutosizeTextArea()
    $('#newpost').css({ 'min-height': '40px' })
  }

  function resetAutosizeTextArea() {
    const evt = document.createEvent('Event')
    evt.initEvent('autosize:destroy', true, false)
    document.querySelector('#newpost').dispatchEvent(evt)
  }

  function discardPost() {
    postForm.reset()
    removeImagePreview()
    hidePostForm()
  }

  function removeImagePreview() {
    $('.selector').remove()
    $('.selector-wrapper').css({ height: '0px' })
    $('.post_file_image_preview_div').css({ display: 'none' })
    $('.post_file_image_preview').remove()
  }
})
