import linkifyElement from 'linkifyjs/element'

document.addEventListener('DOMContentLoaded', function () {
  /* This makes all links clickable using linkify */
  let elems = document.querySelectorAll('.linkify')
  for (let i = 0; i < elems.length; i++) {
    linkifyElement(elems[i])
  }

  /* surface this for comments/create.js.erb */
  window.linkifyElement = linkifyElement
  return
})
