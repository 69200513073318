import './bundle-rating-summary.css'
import { smoothScroll } from 'utils/smoothScroll.js'

// document.addEventListener("DOMContentLoaded", function () {
$(() => {
  const containerSelector = '.c-bundle-rating-summary'
  const container = document.querySelector(containerSelector)
  if (container === null) return

  const scrollTargetSelector = '.c-reviews-section'
  if (document.querySelector(scrollTargetSelector) === null) return

  for (let i = 0; i < container.children.length; i++) {
    container.children[i].addEventListener('click', () => {
      smoothScroll(scrollTargetSelector)
    })
  }
})
