import './field-phone-number.css'
import intlTelInput from 'intl-tel-input'

document.addEventListener('DOMContentLoaded', function () {
  const containerSelector = '.c-field-phone-number'
  const container = document.querySelector(containerSelector)
  if (container === null) return

  const field = container.querySelector('input')

  let iti = intlTelInput(field, {
    initialCountry: field.dataset.defaultCountry,
    formatOnDisplay: true,
    separateDialCode: true,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/js/utils.js',
  })

  field.closest('form').addEventListener('submit', function () {
    if (field.value.length) {
      field.value = iti.getNumber()
    }
  })
})
