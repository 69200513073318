$(function () {
  $('.search-section .arrow').click(function () {
    if ($('.search-section .body').css('display') === 'none') {
      $('.search-section .body').slideDown(400, function () {
        $('.search-section .arrow').removeClass('down').addClass('up')
      })
    } else {
      $('.search-section .body').slideUp(400, function () {
        $('.search-section .arrow').removeClass('up').addClass('down')
      })
    }
  })
})

$(() =>
  $('#search-box .clear-search-btn').click(function () {
    $('#search-box #query').val('')
    $('#search-box #query').focus().select()
  })
)
