import { Lesson } from '../lessons/lesson'

export interface WorkshopProps {
  workshopEl: HTMLDivElement
  lessons: Lesson[]
}

export class Workshop {
  private htmlEl: HTMLDivElement
  public id: string
  public lessons: Lesson[]
  public getIsReviewable: () => boolean

  constructor({ workshopEl, lessons }: WorkshopProps) {
    this.htmlEl = workshopEl
    this.id = this.htmlEl.dataset.id ?? ''
    this.getIsReviewable = () => workshopEl.dataset.isReviewable === 'true'
    this.lessons = lessons
  }
}
