import './hamburger-button.css'

$(function () {
  const selector = '.c-hamburger-button-dropdown'
  if (document.querySelector(selector) === null) {
    return
  }

  $(`${selector}__dropbtn`).click(function (e) {
    const content = document.getElementById('c-hamburger-button-dropdown__dropdown-content')
    content.classList.toggle('c-hamburger-button-dropdown__show')
  })
})

// Close the dropdown menu if the user clicks outside of it
window.onclick = function (event) {
  if (!event.target.matches('.c-hamburger-button-dropdown__dropbtn')) {
    const content = document.getElementById('c-hamburger-button-dropdown__dropdown-content')

    if (content.classList.contains('c-hamburger-button-dropdown__show')) {
      content.classList.remove('c-hamburger-button-dropdown__show')
    }
  }
}

export function closeHamburgerMenu() {
  // Exported only for a bunch of legacy calls that have not been removed. Now a no-op.
}
