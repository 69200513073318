import './filterable-workshops.css'
const List = require('list.js')
import { Pagination } from './pagination.js'
import { WorkshopFilterView } from '../workshop-filter/workshop-filter.js'

document.addEventListener('DOMContentLoaded', function () {
  const containerClass = 'c-filterable-workshops'
  const containerSelector = `.${containerClass}`
  const container = document.querySelector(containerSelector)
  if (container === null) return

  const pageSize = getPageSize()
  const options = {
    valueNames: [{ data: ['tags'] }],
    page: pageSize,
    listClass: `${containerClass}__cards`,
  }
  const workshopList = new List(container, options)
  const pagination = new Pagination(containerSelector, workshopList, pageSize)
  new WorkshopFilterView(workshopList, pagination).run()

  function getPageSize() {
    // 2 rows of 4 for large screen - 3 rows of 3 or 9 of 1 for small screen
    return window.innerWidth > 991 ? 8 : 9
  }
})
