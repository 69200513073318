import './gift-prompt-modal.css'

const OPEN_GIFT_PROMPT_SELECTOR = '.js-open-gift-modal'

document.addEventListener('DOMContentLoaded', function () {
  const openGiftPromptEls = document.querySelectorAll(OPEN_GIFT_PROMPT_SELECTOR)

  if (!openGiftPromptEls || !openGiftPromptEls.length) {
    return
  }

  openGiftPromptEls.forEach((giftPromptEl) => {
    giftPromptEl.addEventListener('click', function (event) {
      MicroModal.show('gift-prompt-modal', {
        disableFocus: true,
        disableScroll: true,
      })
      event.preventDefault()
      return false
    })
  })
})
