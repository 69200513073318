import { hidePaymentOptions } from '../payment-section/payment-section'
import './stripe-form.css'
function resetSubmit(formId, selector) {
  const submit = document.querySelector(`#${formId} ${selector}__submit`)
  submit.disabled = false
  submit.innerText = 'Confirm Payment'
  return false
}

function disableSubmit(formId, selector) {
  const submit = document.querySelector(`#${formId} ${selector}__submit`)
  submit.disabled = true
  if (submit.dataset.customDisableWith) submit.innerText = submit.dataset.customDisableWith
}

function setErrorText(formId, selector, text) {
  const errorElement = document.querySelector(`#${formId} ${selector}__card-errors`)
  errorElement.textContent = text
}

// later use formId to override styling
function cardStyling(formId, selector) {
  const styling = window.getComputedStyle(document.querySelector(`#${formId} ${selector}__stripe-styling`))
  return {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#000',
        color: styling.color || '#000',
        fontWeight: styling.fontWeight || 'normal',
        fontFamily: styling.fontFamily || 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: styling.fontSize || '16px',

        '::placeholder': {
          color: '#c5d7db', //  $light-grey-blue
        },
        ':-webkit-autofill': {
          color: '#fce883',
        },
      },
      empty: {
        iconColor: '#c5d7db', //  $light-grey-blue
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
  }
}

// Submit the form with the token ID.
function stripeTokenHandler(formId, token) {
  // Insert the token ID into the form so it gets submitted to the server
  const form = document.getElementById(formId)
  const hiddenInput = document.createElement('input')
  hiddenInput.setAttribute('type', 'hidden')
  hiddenInput.setAttribute('name', 'stripeToken')
  hiddenInput.setAttribute('value', token.id)
  form.appendChild(hiddenInput)

  form.submit()
}

function createCard(selector, stripe, elements, formId) {
  const form = document.getElementById(formId)
  const cardSelector = `#${formId} ${selector}__card-element`
  const card = elements.create('card', cardStyling(formId, selector))
  const totalPrice = parseFloat(document.querySelector(`#${formId} ${selector}__price`).value)

  if (totalPrice === 0) {
    document.querySelector(`#${formId} ${selector}__card-element`).classList.add('hide')
    hidePaymentOptions()
  }

  card.mount(cardSelector)

  // Handle real-time validation errors from the card Element.
  card.addEventListener('change', function (event) {
    const message = event.error ? event.error.message : ''
    setErrorText(formId, selector, message)
  })

  // Handle form submission.
  document.getElementById(formId).addEventListener('submit', function (event) {
    const price = parseFloat(document.querySelector(`#${formId} ${selector}__price`).value)
    event.preventDefault()
    disableSubmit(formId, selector)
    if (price === 0) return form.submit()
    stripe.createToken(card).then((result) => {
      if (result.error) {
        setErrorText(formId, selector, result.error.message)
        return resetSubmit(formId, selector)
      } else {
        stripeTokenHandler(formId, result.token)
      }
    })
  })
}

document.addEventListener('DOMContentLoaded', function () {
  const componentName = 'c-stripe-form'
  const forms = document.querySelectorAll(`.js-${componentName}`)
  if (!forms.length) return
  const stripe = Stripe(gon.stripe_publishable_key)
  const elements = stripe.elements({
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto&display=swap',
      },
    ],
  })

  for (let i = 0; i < forms.length; i++) {
    createCard(`.${componentName}`, stripe, elements, forms[i].getAttribute('id'))
  }
})
