import './brightcove-custom-overlay.css'

export class BrightcoveCustomOverlay {
  constructor(player, overlaySelector) {
    this.player = player
    this.overlaySelector = overlaySelector
    this.overlayElem = document.querySelector(overlaySelector)
    if (this.overlayElem) this.initializeOverlay(this.overlayElem, this.player)
  }

  initializeOverlay(overlayElem, player) {
    player.overlay({
      content: overlayElem.outerHTML,
      overlays: [JSON.parse(overlayElem.dataset.overlayData)],
    })
  }

  showCustomOverlay() {
    if (this.overlayElem) document.querySelector(this.overlaySelector).style.display = 'flex'
  }

  hideCustomOverlay() {
    if (this.overlayElem) document.querySelector(this.overlaySelector).style.display = 'none'
  }
}
