import './header-dropdown.css'
import { Spinner } from 'spin.js'

// Relies on jQuery
const blockNames = ['header-notifications', 'header-requests']
$(() =>
  blockNames.forEach(function (blockName) {
    $(`.${blockName} .dropdown-menu`).on('show.headerMenu', function () {
      showHeaderDropdown(blockName)
    })
  })
)

var showLoadedDropdown = function (responseHtml, blockName) {
  const $responseHtml = $(responseHtml)
  const spinner = document.querySelector(`.${blockName}__spinner`)
  if (spinner !== null) spinner.parentNode.removeChild(spinner)
  const $dropdownContainer = $(`.${blockName}__list`)
  $($dropdownContainer).append($responseHtml)
  $('html, body').animate(() => ({ scrollTop: $responseHtml.offset().top }), 500)

  // add tracking
  window.trackSection($responseHtml)
}

const loadDropdown = function (blockName) {
  const url = $(`.${blockName}__action--js`).data('path')
  $.ajax({ url })
    .done(function (response) {
      showLoadedDropdown(response, blockName)
    })
    .always(function () {
      $(`.${blockName}__unread-icon`).hide()
    })
}

const showSpinner = function (listElem, blockName) {
  const spinnerElem = document.createElement('li')
  spinnerElem.setAttribute('class', `header-spinner ${blockName}__spinner`)
  listElem.appendChild(spinnerElem)
  // eventually replace spinner with webpack imported version
  spinnerElem.appendChild(
    new Spinner({
      radius: 9,
      lines: 11,
      width: 4,
      color: '#4B6A8A',
    }).spin().el
  )
}

var showHeaderDropdown = function (blockName) {
  const listElem = document.querySelector(`.${blockName}__list`)
  if (listElem.dataset.requiresRefresh !== 'false') {
    showSpinner(listElem, blockName)
    loadDropdown(blockName)
    listElem.dataset.requiresRefresh = 'false'
  }
}
