import Cookies from 'universal-cookie'
const cookies = new Cookies()
const utmData = cookies.get('utm-current')

export default function postTrackingEvent(trackableId, trackableType, title) {
  if (utmData) {
    fetch('/tracking/tracking_events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData(trackableId, trackableType, title)),
    })
  }
}

function eventData(trackableId, trackableType, title) {
  return {
    authenticity_token: document.querySelector('#utm_authenticity').value,
    tracking_event: Object.assign(
      {
        trackable_id: trackableId,
        trackable_type: trackableType,
        title: title,
      },
      utmData
    ),
  }
}
