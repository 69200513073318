import { smoothScroll } from 'utils/smoothScroll.js'

document.addEventListener('DOMContentLoaded', function () {
  const pageSelector = '.p-bundle-landing'
  if (document.querySelector(pageSelector) === null) return

  const watchButton = document.querySelector(`${pageSelector}__start-watching--js`)
  if (watchButton !== null) {
    watchButton.addEventListener('click', function () {
      smoothScroll('.c-workshop-section-item, .c-program-section-overview__intro')
    })
  }
})

document.addEventListener('DOMContentLoaded', function () {
  const getStartedButton = document.querySelector(`.p-bundle-landing__get-started--js`)
  if (getStartedButton !== null) {
    getStartedButton.addEventListener('click', function () {
      smoothScroll('.c-paywall-offering-section')
    })
  }
})
