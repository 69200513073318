import amazonPayButton from 'components/amazon-pay-button/amazon-pay-button.js'
import amazonPayForm from 'components/amazon-pay-form/amazon-pay-form.js'

/* necessary to set ids */
window.onAmazonLoginReady = () => {
  amazon.Login.setClientId('amzn1.application-oa2-client.b6408583275d437a81b101dd0ee2797d')
}

const documentReady = () => {
  return document.readyState === 'complete' || document.readyState === 'interactive'
}

const amazonPaymentsReady = () => {
  amazonGlobal()
  amazonPayButton()
  amazonPayForm()
}

const getURLParameter = (name, source) => {
  return (
    decodeURIComponent(
      (new RegExp('[?|&|#]' + name + '=' + '([^&]+?)(&|#|;|$)').exec(source) || [, ''])[1].replace(/\+/g, '%20')
    ) || null
  )
}

const amazonGlobal = () => {
  var accessToken = getURLParameter('access_token', location.hash)

  if (typeof accessToken === 'string' && accessToken.match(/^Atza/)) {
    var cookieString = 'amazon_Login_accessToken=' + accessToken
    if (!gon.development) cookieString += ';secure'
    document.cookie = cookieString
  }

  if (gon.resetAmazon === 'true') amazon.Login.logout()

  amazon.Login.setUseCookie(true)
}

window.onAmazonPaymentsReady = () => {
  if (documentReady()) amazonPaymentsReady()
  else $(amazonPaymentsReady)
}
