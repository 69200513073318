$(function () {
  if (window.location.pathname === '/webusers/share') {
    $('.modal.share').modal()
  }

  $('#hamburger-menu .js-share').click(function () {
    closeHamburgerMenu()
    $('.modal.share').modal()
    return false
  })

  // This case deals with closing the hamburger menu by clicking the 'X'
  $('#hamburger-menu .dropdown-toggle').click(function () {
    closeHamburgerMenu()
  })

  // note bootsrap 2.3.2 does not trigger events for hide dropdown toggles
  $('#header .header-dropdown .dropdown-toggle').click(function () {
    const $parent = $(this).parents('.header-dropdown')
    if (!$('.header-dropdown.open').is($parent)) {
      // close other headers
      closeHeaderMenu($('.header-dropdown.open'))
    }
    if ($parent.hasClass('open')) {
      closeHeaderMenu($parent)
    } else {
      openHeaderMenu($parent)
    }
  })

  // These apply to the requests, notifications, and messages dropdowns in the
  // header (NOT the hamburger menu)
  $('body').click(function (e) {
    // Clicking outside of menu to close a header dropdown
    if (
      !$('.header-dropdown.open .dropdown-menu').is(e.target) &&
      $('.header-dropdown.open .dropdown-menu').has(e.target).length === 0 &&
      $('.open').has(e.target).length === 0 &&
      $('.header-dropdown.open').length
    ) {
      $('.header-dropdown.open').trigger('hide.headerMenu').removeClass('open')
      $('html').removeClass('modal-open')
      // Clicking the x button to close a header dropdown
    } else if ($('.header-dropdown.open .dropdown-menu .dropdown-toggle img').is(e.target)) {
      // same action in this case, conditional just was too complex to combine
      $('.header-dropdown.open').trigger('hide.headerMenu').removeClass('open')
      $('html').removeClass('modal-open')
    }
  })
})

var openHeaderMenu = function ($dropdownContainer) {
  $('html').addClass('modal-open')
  if (screen.width > 550) {
    $('html').css('overflow-y', 'scroll')
  }
  $dropdownContainer.addClass('open')
  $dropdownContainer.find('.dropdown-menu').trigger('show.headerMenu')
  $dropdownContainer.find('.notifcircleicon').css('display', 'none')
}
var closeHamburgerMenu = function () {
  $('html').removeClass('modal-open')
  $('#hamburger-menu').removeClass('in')
  $('#hamburger-menu').trigger('hide.bs.modal')
}
var closeHeaderMenu = function ($dropdownContainer) {
  $('html').removeClass('modal-open')
  $dropdownContainer.find('.dropdown-menu').trigger('hide.headerMenu')
  return $dropdownContainer.removeClass('open')
}

$(document).keyup(function (e) {
  if (e.keyCode === 27) {
    // escape key
    if ($('#hamburger-menu').hasClass('in')) {
      closeHamburgerMenu()
    } else if ($('.header-dropdown.open').length) {
      closeHeaderMenu($('.header-dropdown.open'))
    }
  }
})
