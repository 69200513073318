import Cookies from 'universal-cookie'
const cookies = new Cookies()
const utmData = cookies.get('utm-current')

document.addEventListener('DOMContentLoaded', function () {
  if (gon.ecommerceData) postUTMsPayment(gon.ecommerceData.transactionId)
})

const postUTMsPayment = (transactionId) => {
  if (utmData) {
    fetch('/payment_receipt_trackings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(paymentData(transactionId, utmData)),
    })
  }
}

const getFbp = () => {
  return cookies.get('_fbp')
}

const getFbc = () => {
  /* later can try to generate fbc if for adblocker/cookie reasons _fbc is
  not set */
  return cookies.get('_fbc')
}

const paymentData = (transactionId, utmData) => {
  return {
    authenticity_token: document.querySelector('#utm_authenticity').value,
    payment_receipt_id: transactionId,
    payment_receipt_tracking: Object.assign({}, utmData, {
      event_source_url: window.location.href,
      fbp: getFbp(),
      fbc: getFbc(),
    }),
  }
}
