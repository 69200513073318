// Relies on jQuery
$(function () {
  if ($('.js-data-table').length) {
    const { dataset } = $('.js-data-table')[0]
    $('.js-data-table').DataTable({
      searching: dataset.searching === 'true',
      paging: dataset.paging === 'true',
      info: dataset.info === 'true',
      ordering: dataset.ordering ? dataset.ordering : true,
      order: [[dataset.sortCol ? dataset.sortCol : 0, dataset.sortOrder ? dataset.sortOrder : 'asc']],
      language: {
        searchPlaceholder: 'Search',
        search: '',
      },
      dom: 'flrtip',
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All'],
      ],
      columnDefs: [
        {
          targets: 'no-sort',
          orderable: false,
        },
      ],
    })
  }
})

$(function () {
  const $table = $('.js-memberships-data-table')
  if (!$table.length) {
    return
  }

  var fillMembershipData = function ($table, datatable, page) {
    $.ajax(`${$table.data('ajax-path')}?page=${page}`, {
      type: 'GET',
      dataType: 'json',
      success(response, textStatus, jqXHR) {
        if (response.data.length) {
          datatable.rows.add(response.data).draw()
          fillMembershipData($table, datatable, page + 1)
        }
      },
    })
  }

  const datatable = $table.DataTable({
    ajax: `${$table.data('ajax-path')}?page=1`,
    deferRender: true,
    language: {
      searchPlaceholder: 'Search',
      search: '',
    },
    dom: 'flrtip',
    lengthMenu: [
      [25, 50, 100],
      [25, 50, 100],
    ],
    columnDefs: [
      {
        targets: 'no-sort',
        orderable: false,
      },
    ],
  })
  fillMembershipData($table, datatable, 2)
})

// groups/_fields
$(function () {
  if ($('#group_hood_type_1').is(':checked')) {
    $('#stateselect').hide()
    $('#group_state').prop('required', false)
  }
  $('#group_hood_type_0').on('change', function () {
    $('#group_state').prop('required', true)
    $('#stateselect').show('fast')
  })
  $('#group_hood_type_1').on('change', function () {
    $('#group_state').prop('required', false)
    $('#group_state').val('')
    $('#stateselect').hide('fast')
  })
})

// groups/_post_form_not_a_member && shared/_feed_not_a_member
$(document).ready(function () {
  $('.redirect_join').click(function () {
    document.getElementById('group-join-btn').click()
  })
})

$(document).ready(function () {
  // Show "Show More" link if needed
  $('.pspacing.block-ellipsis-hoods-short').each(function () {
    const actualHeight = $(this).children('div').height()
    const heightLimit = $(this).height()
    if (actualHeight > heightLimit) {
      return $(this).siblings('.groupshowmorelink-short').css({ display: 'block' })
    }
  })
  // Clicking on post body should expand it if there are ellipsis
  $('.pspacing.block-ellipsis-hoods-short').click(function () {
    $(this).attr('class', 'pspacing')
    $(this).siblings('.groupshowmorelink-short').css({ display: 'none' })
  })
  // Hide "Show More" link if it is clicked and show the remaining post
  $('.groupshowmorelink-short').click(function () {
    $(this).css({ display: 'none' })
    $(this).siblings('.block-ellipsis-hoods-short').attr({ class: 'pspacing' })
  })

  return $('form#new_post').submit(function () {
    if (!$(this).hasClass('waiting-approval') && document.getElementById('required-name-modal')) {
      $(this).addClass('waiting-approval')
      $('#required-name-modal').on('shown.bs.modal', () => $('#required-name-modal #user_name').focus())
      $('#required-name-modal').modal({
        backdrop: 'static',
        keyboard: false,
      })
      return false
    } else {
      setpreview()
      return true
    }
  })
})

var setpreview = function () {
  const preview = $('#newpost').data('preview')
  if (preview && preview.title && preview.thumbnail_url) {
    $('#post_link_thumbnail_url').val(preview.thumbnail_url ? preview.thumbnail_url : '')
    $('#post_link_title').val(preview.title ? preview.title : '')
    $('#post_link_original_url').val(preview.original_url ? preview.original_url : '')
    return $('#post_link_description').val(preview.description ? preview.description : '')
  }
}
