$(function () {
  if ($('#detailed-info').length) {
    $('.signup.modal-less form').submit(function (e) {
      if (gon.skip_matching) {
        return true
      } else {
        const form = this
        $('#detailed-info').css('display', 'none')
        window.scrollTo(0, 0)
        $('#matching').css('display', 'flex')
        setTimeout(() => form.submit(), 1500)
        return false
      }
    })
  }
})
