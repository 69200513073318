import sendStepStatusRequest from '@/utils/sendStepStatusRequest'
import { HandoutLesson } from './handout-lesson'
import { SupplementalVideoLesson } from './supplemental-video-lesson'
import { VideoLesson } from './video-lesson'

const ACTIVE_CLASS_NAME = 'c-panel-step--active'
const COMPLETED_DATA_ATTRIBUTE = '1'
const COMPLETED_CLASS_NAME = 'c-panel-step--completed'
const PAUSED_CLASS_NAME = 'c-panel-step--paused'

const MINIMUM_SECONDS_BETWEEN_SAVES = 8
const COMPLETED_PROGRESS_PERCENT = 100

export type Lesson = VideoLesson | HandoutLesson | SupplementalVideoLesson

export abstract class CourseLesson {
  protected htmlEl: HTMLDivElement
  protected isActive: boolean
  public id: string
  public title: string
  public isComplete: boolean
  public progressPercentage: number
  public progressUpdatedAt: Date | undefined

  constructor(lessonEl: HTMLDivElement) {
    this.htmlEl = lessonEl
    this.id = lessonEl.dataset.id ?? ''
    this.title = lessonEl.dataset.title ?? ''
    this.isComplete = lessonEl.dataset.isComplete === '1'
    this.isActive = lessonEl.classList.contains(ACTIVE_CLASS_NAME)
    this.progressPercentage = parseFloat(this.htmlEl.dataset.progressPercentage ?? '0')
    this.progressUpdatedAt = this.htmlEl.dataset.progressUpdatedAt
      ? new Date(this.htmlEl.dataset.progressUpdatedAt)
      : undefined
  }

  public markActive() {
    this.isActive = true
    this.htmlEl.classList.add(ACTIVE_CLASS_NAME)
  }

  public markInactive() {
    this.isActive = false
    this.htmlEl.classList.remove(ACTIVE_CLASS_NAME)
    this.htmlEl.classList.remove(PAUSED_CLASS_NAME)
  }

  public markComplete() {
    this.isComplete = true
    this.htmlEl.dataset.isComplete = COMPLETED_DATA_ATTRIBUTE
    this.htmlEl.classList.add(COMPLETED_CLASS_NAME)
  }

  public onClick(onClick: () => void) {
    this.htmlEl.addEventListener('click', () => onClick())
  }

  public saveProgress(progressPercentage: number) {
    const progressHasUpdated = this.progressPercentage !== progressPercentage

    if (progressPercentage && progressHasUpdated) {
      this.progressPercentage = progressPercentage

      if (this.shouldSaveProgress()) {
        this.progressUpdatedAt = new Date()
        sendStepStatusRequest(this.id, gon.current_user_id, progressPercentage)
      }
    }
  }

  private shouldSaveProgress() {
    if (this.progressPercentage == COMPLETED_PROGRESS_PERCENT) {
      return true
    }
    const currentTime = new Date().getTime()
    const lastSavedTime = this.progressUpdatedAt?.getTime()

    if (!lastSavedTime) {
      return true
    }

    const secondsSinceLastSave = (currentTime - lastSavedTime) / 1000

    return secondsSinceLastSave > MINIMUM_SECONDS_BETWEEN_SAVES
  }
}
