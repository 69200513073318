import Rails from 'rails-ujs'

var handleConfirm = function (element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element)
  }
}

var allowAction = function (element) {
  if (element.getAttribute('data-confirm') === null) {
    return true
  }
  showConfirmationDialog(element)
  return false
}

// Display the confirmation dialog
var showConfirmationDialog = function (link) {
  const message = link.getAttribute('data-confirm')
  const cancel = link.dataset.cancel ? link.dataset.cancel : 'Cancel'
  const ok = link.dataset.ok ? link.dataset.ok : 'OK'
  const $modal = $(`<div class="modal default" id="confirmationDialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <a class="close" data-dismiss="modal">×</a>
                <h1>${message}</h1>
              </div>
              <div class="modal-footer">
                <a data-dismiss="modal" class="btn">${cancel}</a>
                <a data-dismiss="modal" class="btn btn-primary confirm">${ok}</a>
              </div>
            </div>
          </div>
        </div>`)

  $modal.modal()
  $('#confirmationDialog .confirm').on('click', () => confirmed(link))
}

var confirmed = function (element) {
  element.removeAttribute('data-confirm')
  element.click()
}

Rails.delegate(document, 'a[data-confirm]', 'click', handleConfirm)

Rails.start()
