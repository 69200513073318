import { CourseLesson } from './lesson'

const PAUSED_CLASS_NAME = 'c-panel-step--paused'

export class VideoLesson extends CourseLesson {
  sourceUrl: string
  sourceType: string

  constructor(lessonEl: HTMLDivElement) {
    super(lessonEl)
    this.sourceUrl = this.htmlEl.dataset.sourceUrl ?? ''
    this.sourceType = this.htmlEl.dataset.sourceType ?? ''
  }

  markNotPaused() {
    this.htmlEl.classList.remove(PAUSED_CLASS_NAME)
  }

  markPaused() {
    this.htmlEl.classList.add(PAUSED_CLASS_NAME)
  }
}
