import './accordion-item-card.css'

import BadgerAccordion from 'badger-accordion'

const user_signed_in = !!gon.current_user_id
const loginStatus = { signed_in: user_signed_in }

/* no tracking for now */
const _props = Object.assign({}, loginStatus, gon.trackingData)
function _trackingData(elem, baseProps) {
  return Object.assign({}, baseProps, $(elem).data('tracking-data'))
}

// document.addEventListener("DOMContentLoaded", function () {
$(() => {
  const accordions = document.querySelectorAll('.js-c-accordion-item-card')
  Array.from(accordions).forEach((accordion) => {
    const ba = new BadgerAccordion(accordion)
  })
})
