import { queryElement, queryElementAll } from '@/utils/querySelectorHelpers'

const CONTAINER_SELECTOR = '.c-panel-workshop-list'
const VIDEO_PLAYER_CONTAINER_SELECTOR = '.c-panel-video-player'
const VIDEO_PLAYER_SELECTOR = 'video-js'
const WORKSHOP_SELECTOR = '.c-panel-workshop'
const STEP_SELECTOR = '.c-panel-step'
const HANDOUT_CONTAINER_SELECTOR = '.c-panel-video-player-handout-overlay'
const SUPPLEMENTAL_VIDEO_CONTAINER_SELECTOR = '.c-panel-video-player-supplemental-video-overlay'
const REVIEW_PROMPT_SELECTOR = '.c-class-review-prompt'
/**
 * The PanelWorkshopListHtml class simplifies finding the html elements that
 * make up a "panel workshop list".
 */
export abstract class PanelWorkshopListHtml {
  static getContainerElement() {
    try {
      return queryElement(document, HTMLDivElement, CONTAINER_SELECTOR)
      // eslint-disable-next-line no-empty
    } catch {}
  }

  static getWorkshopElements(containerEl: HTMLDivElement) {
    const workshopElements = queryElementAll(containerEl, HTMLDivElement, WORKSHOP_SELECTOR)

    return Array.from(workshopElements)
  }

  static getVideoPlayerElement() {
    const videoContainer = this.getVideoContainer()
    const playerElement = queryElement(videoContainer, HTMLElement, VIDEO_PLAYER_SELECTOR)

    if (!videoContainer || !playerElement) {
      return undefined
    }

    return playerElement
  }

  static getVideoContainer() {
    return queryElement(document, HTMLDivElement, VIDEO_PLAYER_CONTAINER_SELECTOR)
  }

  static getHandoutOverlayElement() {
    return queryElement(document, HTMLDivElement, HANDOUT_CONTAINER_SELECTOR)
  }

  static getSupplementalVideoOverlayElement() {
    return queryElement(document, HTMLDivElement, SUPPLEMENTAL_VIDEO_CONTAINER_SELECTOR)
  }

  static getReviewPromptElement() {
    return queryElement(document, HTMLDivElement, REVIEW_PROMPT_SELECTOR)
  }

  static getStepElements(workshopElement: HTMLDivElement) {
    const stepElements = queryElementAll(workshopElement, HTMLDivElement, STEP_SELECTOR)

    return Array.from(stepElements)
  }

  static getIsTinyhoodUser() {
    const videoContainer = this.getVideoContainer()
    return videoContainer.dataset.isTinyhoodUser === 'true'
  }
}
