import autosize from 'autosize'
$(function () {
  if (!window.comments) {
    window.comments = {}
  }

  const addListeners = function () {
    autosize($('.edit_comment textarea'))
    return $('.cancel-comment').click(function (e) {
      const id = $(this).data('id')
      $('li.comment#' + id).css('display', 'list-item')
      return $('.edit_comment.' + id).remove()
    })
  }

  window.comments.addListeners = () => addListeners()
})
