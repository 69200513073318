import './course-section-previews-alt.css'

$(function () {
  if (!$('.c-course-section-previews-alt').length) return
  const takeawayColumnCount = $('.c-workshop-section-item__takeaway-list')
    .css('grid-template-columns') /* outputs "99px 99px 99px 99px" */
    .split(' ').length
  const rowsShown = window.innerWidth > 1000 ? 1 : 2
  const maxShown = takeawayColumnCount * rowsShown

  const $takeawayLists = $('.c-workshop-section-item__takeaway-list')
  for (let i = 0; i < $takeawayLists.length; i++) {
    const totalTakeaways = $takeawayLists[i].children.length
    if (maxShown < totalTakeaways) {
      const hiddenItemCount = totalTakeaways - maxShown + 1
      $($takeawayLists[i])
        .find(`> div:nth-of-type(1n+${maxShown})`)
        .addClass('c-course-section-previews-alt__hidden-from-overflow')

      const $showMore = $(
        `<div class='c-course-section-previews-alt__show-more'><button>+ ${hiddenItemCount} More</button></div>`
      )
      $($takeawayLists[i]).append($showMore)

      $showMore.css('display', 'block').on('click', function () {
        $($takeawayLists[i])
          .find('.c-course-section-previews-alt__hidden-from-overflow')
          .removeClass('c-course-section-previews-alt__hidden-from-overflow')

        this.remove()
      })
    }
  }
})
