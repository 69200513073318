import { CourseView, CourseViewUpdater } from './course-view'
import { VideoPlayer } from './video-player/video-player'
import { VideoLesson } from './lessons/video-lesson'
import { SupplementalVideoLesson } from './lessons/supplemental-video-lesson'
import { Lesson } from './lessons/lesson'
import { Video } from './video-player'

export class VideoPlayerUpdater implements CourseViewUpdater {
  private player: VideoPlayer
  private previousLesson: Lesson | undefined

  constructor(player: VideoPlayer) {
    this.player = player
  }

  run(courseView: CourseView) {
    const lesson = courseView.getCurrentLesson()

    if (lesson instanceof VideoLesson || lesson instanceof SupplementalVideoLesson) {
      this.videoLessonUpdate(lesson, courseView)
    } else {
      this.player.reset()
    }

    this.previousLesson = lesson
  }

  private videoLessonUpdate(lesson: VideoLesson | SupplementalVideoLesson, courseView: CourseView) {
    const initialLessonLoad = !this.previousLesson?.id
    const lessonAlreadyActive = lesson.id === this.previousLesson?.id

    if (initialLessonLoad) {
      lesson.markPaused()
    }

    if (lessonAlreadyActive && lesson instanceof VideoLesson) {
      this.player.togglePlayback()
      return
    } else if (lessonAlreadyActive && lesson instanceof SupplementalVideoLesson) {
      return
    }

    this.setVideoPlayerVideo(lesson, courseView)
  }

  private setVideoPlayerVideo(lesson: VideoLesson | SupplementalVideoLesson, courseView: CourseView) {
    const nextLesson = courseView.getNextLesson()
    const supplementalVideoLesson = lesson instanceof SupplementalVideoLesson
    const shouldAutoplay = !!this.previousLesson && !supplementalVideoLesson
    const startAtProgress = lesson.progressPercentage > 95 ? 0 : lesson.progressPercentage

    const video: Video = {
      id: lesson.id,
      title: lesson.title,
      sourceUrl: lesson.sourceUrl,
      sourceType: lesson.sourceType,
    }

    this.player.setVideo({
      video,
      shouldAutoplay,
      startAtProgress,
      onPlay: ({ duration, time }) => {
        lesson.saveProgress(this.getProgress(time, duration))
        lesson.markNotPaused()
      },
      onPause: () => {
        lesson.markPaused()
      },
      onEnd: () => {
        lesson.markComplete()

        if (nextLesson) {
          nextLesson.progressPercentage = 0
          courseView.activate(nextLesson, true)
        }
      },
      onProgress: ({ time, duration }) => {
        lesson.saveProgress(this.getProgress(time, duration))
      },
    })
  }

  private getProgress(time: number, duration: number) {
    return Math.round((time / duration) * 100.0)
  }
}
