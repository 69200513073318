$(function () {
  $('.navbar-header, .header-requests__list, .hoodrequest-review-card').on('click', '.js-reject-btn', function () {
    const $form = $(this).parents('form')
    openRejectModal($form)
  })

  var openRejectModal = function ($originalForm) {
    initRejectForm($originalForm)
    $('#reject-modal').modal()
  }

  var initRejectForm = function ($originalForm) {
    $('#reject-modal select').val('')
    $('#reject-modal textarea').val('')
    setUpAutoResponses($originalForm)
    setUpSubmit($originalForm)
  }

  var setUpAutoResponses = function ($originalForm) {
    const groupName = $originalForm.data('name')
    return $('#reject-modal select').change(function () {
      const str = $('#reject-modal select option:selected').data('text')
      if (typeof str !== 'undefined') {
        $('#reject-modal textarea').val(str.replace(/\*\|HOOD_NAME\|\*/g, groupName))
      }
    })
  }

  var setUpSubmit = function ($originalForm) {
    $('#reject-modal form').submit(function () {
      disableSubmit($(this).find("[type='submit']"))
      setRejectFields($originalForm, $(this))
      $originalForm.submit()
      return false
    })
  }

  var disableSubmit = function ($submitBtn) {
    $submitBtn.prop('disabled', true).val($submitBtn.data('disable-with'))
  }
  var setRejectFields = function ($originalForm, $rejectForm) {
    $originalForm.find('.reject_reason_id').val($rejectForm.find('#reject_reason_id').val())
    $originalForm.find('.reject_reason_content').val($rejectForm.find('#reject_reason_content').val())
  }
})
