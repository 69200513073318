import './dual-registration-form.css'

const selector = '.c-dual-registration-form'

document.addEventListener('DOMContentLoaded', function () {
  const container = document.querySelector(`${selector}--selected`)
  const toggles = document.querySelectorAll(`${selector}__toggle`)
  if (container === null) return

  for (var i = 0; i < toggles.length; i++) {
    toggles[i].addEventListener('click', toggleClicked)
  }
})

function toggleClicked() {
  const selectedSelector = `${selector}--selected`
  const selectedContainer = document.querySelector(selectedSelector)
  const unselectedContainer = document.querySelector(`${selector}:not(${selectedSelector})`)
  selectedContainer.classList.remove(selectedSelector.substring(1))
  unselectedContainer.classList.add(selectedSelector.substring(1))
}
