import Cookies from 'universal-cookie'
import getDomain from 'utils/getDomain.js'
import parseUTMS from 'utils/parseUTMS.js'
import getDevice from 'utils/getDevice.js'

const allowListedDomains = ['payments.amazon.com', 'payments-sandbox.amazon.com/']

const queryStringParser = require('query-string')

const cookies = new Cookies()
let expiration = new Date()
expiration.setDate(expiration.getDate() + 60)
const defaultCookieParams = {
  path: '/',
  expires: expiration,
}

const utms = parseUTMS(location.search)
const referrerData = getReferrerData(document.referrer, window.location.href)
if (isFromImpactRadius(location.search)) {
  processImpactRadius(Object.assign({}, utms, referrerData))
} else if (validUtm(utms)) {
  storeData(Object.assign({}, utms, referrerData))
} else if (Object.keys(referrerData).length !== 0) {
  storeData(referrerData)
}

function getReferrerData(referrer, currentUrl) {
  if (shouldTrackDomain(referrer, currentUrl)) {
    /* sent to rails backend so for now snake case keys */
    return {
      initial_referrer: document.referrer,
      initial_referring_domain: getDomain(document.referrer),
    }
  } else {
    return {}
  }
}

function shouldTrackDomain(referrer, currentUrl) {
  return !!referrer && !ignoredReferrer(referrer) && getDomain(referrer) !== getDomain(currentUrl)
}

function processImpactRadius(utms) {
  const impactRadiusUtms = {
    utm_source: 'affiliate',
    utm_medium: 'impact',
  }
  if (validUtm(utms)) {
    storeData(Object.assign({}, utms, impactRadiusUtms))
  } else {
    storeData(impactRadiusUtms)
  }
}

function isFromImpactRadius(queryString) {
  const parsedQueryString = queryStringParser.parse(queryString)
  return !!parsedQueryString.irclickid // irclickid is set for impact radius
}

function storeData(utms) {
  const finalData = Object.assign({}, defaultTrackingData(), utms)
  cookies.set('utm-current', finalData, defaultCookieParams)
  if (!cookies.get('utm-original')) cookies.set('utm-original', finalData, { path: '/' })
}

function defaultTrackingData() {
  /* sent to rails backend so for now snake case keys */
  return {
    initial_url: window.location.href,
    medium: gon.trackingData.medium,
    device: getDevice(navigator.userAgent),
    user_agent: navigator.userAgent,
    ip_address: gon.tracking.ip,
    action_source: 'website',
  }
}

function validUtm(utms) {
  if (Object.keys(utms).length === 0) return false
  return !isNewsletterOverwriting()
}

// Function in both utm & url gathering, later refactor when separate
//  "source" and utm_source in regards to tracking source of purchases
// Do not override other utms with newsletter campaign
function isNewsletterOverwriting() {
  const newsletterCampaign = 'newsletter_signup'
  const parsedQueryString = queryStringParser.parse(location.search)
  if (parsedQueryString.utm_campaign !== newsletterCampaign) return false
  return existingUtmCurrent() || existingReferringDomain()
}

function existingUtmCurrent() {
  const index = `utm-current`
  return !!cookies.get(index) && !!cookies.get(index).utm_source
}

function ignoredReferrer(referrer) {
  if (gon.untrackedPageReferrer) return true
  return isAllowListedDomain(getDomain(referrer)) || isNewsletterOverwriting()
}

function existingReferringDomain() {
  const index = `utm-current`
  return !!cookies.get(index) && !!cookies.get(index).initial_referrer
}

function isAllowListedDomain(domain) {
  return allowListedDomains.indexOf(domain) !== -1
}
