// depends on jQuery
$(document).ready(function () {
  $('#jobbtn0').hover(
    function () {
      $('#jobbtn0').css({ color: 'white', 'background-color': '#a09dc2' })
      $('#location0').css({
        color: 'white',
        'background-color': 'transparent',
      })
    },
    function () {
      $('#jobbtn0').css({ color: '#aba8ca', 'background-color': '#F3F3F3' })
      $('#location0').css({
        color: '#777777',
        'background-color': 'transparent',
      })
    }
  )

  $('#jobbtn1').hover(
    function () {
      $('#jobbtn1').css({ color: 'white', 'background-color': '#a09dc2' })
      $('#location1').css({
        color: 'white',
        'background-color': 'transparent',
      })
    },
    function () {
      $('#jobbtn1').css({ color: '#aba8ca', 'background-color': '#F3F3F3' })
      $('#location1').css({
        color: '#777777',
        'background-color': 'transparent',
      })
    }
  )

  $('#jobbtn2').hover(
    function () {
      $('#jobbtn2').css({ color: 'white', 'background-color': '#a09dc2' })
      $('#location2').css({
        color: 'white',
        'background-color': 'transparent',
      })
    },
    function () {
      $('#jobbtn2').css({ color: '#aba8ca', 'background-color': '#F3F3F3' })
      $('#location2').css({
        color: '#777777',
        'background-color': 'transparent',
      })
    }
  )

  $('#jobbtn3').hover(
    function () {
      $('#jobbtn3').css({ color: 'white', 'background-color': '#a09dc2' })
      $('#location3').css({
        color: 'white',
        'background-color': 'transparent',
      })
    },
    function () {
      $('#jobbtn3').css({ color: '#aba8ca', 'background-color': '#F3F3F3' })
      $('#location3').css({
        color: '#777777',
        'background-color': 'transparent',
      })
    }
  )

  $('#jobbtn4').hover(
    function () {
      $('#jobbtn4').css({ color: 'white', 'background-color': '#a09dc2' })
      $('#location4').css({
        color: 'white',
        'background-color': 'transparent',
      })
    },
    function () {
      $('#jobbtn4').css({ color: '#aba8ca', 'background-color': '#F3F3F3' })
      $('#location4').css({
        color: '#777777',
        'background-color': 'transparent',
      })
    }
  )
})
