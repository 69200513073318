import MicroModal from 'micromodal'

document.addEventListener('DOMContentLoaded', function () {
  const micromodals = document.querySelectorAll('.micromodal')

  if (!micromodals || !micromodals.length) {
    return
  }

  MicroModal.init()
})
