export function getDefaultFilter(filterSelector) {
  if (isValidFilter(filterSelector, window.global_getAnchor())) {
    return window.global_getAnchor()
  } else if (isValidFilter(filterSelector, gon.default_class_category)) {
    return gon.default_class_category
  } else {
    return document.querySelector(`${filterSelector}[data-title]`).dataset.title
  }
}

function isValidFilter(selector, title) {
  return title !== null && typeof title !== 'undefined' && document.querySelector(`${selector}[data-title='${title}']`)
}
