$(function () {
  const htmlId = '#question-previews'
  const $topics = $(`${htmlId} .convo-topics [data-js-convo]`)
  const $previews = $(`${htmlId} .convo-preview .right-preview`)
  if (!$topics.length) {
    return
  }

  if (screen.width > 550) {
    $topics.click(function () {
      const id = $(this).data('js-convo')
      $topics.removeClass('selected')
      $previews.removeClass('selected')
      $topics.eq(id).addClass('selected')
      $previews.eq(id).addClass('selected')
    })
  } else {
    $previews.each(function () {
      const $preview = $(this)
      $preview.children('h6, .toggles').click(function () {
        $preview.toggleClass('selected')
      })
    })
  }
  $('#question-previews .js-read-more').click(function () {
    $(`.modal[data-js-convo='${$(this).data('js-convo')}']`)
      .appendTo('body')
      .modal()
  })
})
