import './header-dropdown-v1.css'
import { Spinner } from 'spin.js'

// Relies on jQuery
const selector = '.c-header-dropdown-v1'
$(function () {
  $(`${selector}__icon`).click(function (e) {
    showHeaderDropdown(document.querySelector(`.header-${this.dataset.title}`), selector)
  })
})

function showLoadedDropdown(responseHtml, component, selector) {
  const $responseHtml = $(responseHtml)
  const spinner = component.querySelector('.header-spinner')
  if (spinner !== null) spinner.parentNode.removeChild(spinner)
  const $dropdownContainer = $(component).find(`${selector}__list`)
  $($dropdownContainer).append($responseHtml)
  $('html, body').animate(() => ({ scrollTop: $responseHtml.offset().top }), 500)

  // add tracking
  window.trackSection($responseHtml)
}

function loadDropdown(component, selector) {
  const url = component.querySelector(`${selector}__icon`).dataset.path
  $.ajax({ url })
    .done(function (response) {
      showLoadedDropdown(response, component, selector)
    })
    .always(function () {
      const unreadIcon = component.querySelector('.unread-icon')
      if (unreadIcon) unreadIcon.parentNode.removeChild(unreadIcon)
    })
}

function showSpinner(listElem) {
  const spinnerElem = document.createElement('li')
  spinnerElem.setAttribute('class', 'header-spinner')
  listElem.appendChild(spinnerElem)
  // eventually replace spinner with webpack imported version
  spinnerElem.appendChild(
    new Spinner({
      radius: 9,
      lines: 11,
      width: 4,
      color: '#4B6A8A',
    }).spin().el
  )
}

function showHeaderDropdown(parentElem, selector) {
  const listElem = parentElem.querySelector(`${selector}__list`)
  if (listElem.dataset.requiresRefresh !== 'false') {
    showSpinner(listElem)
    loadDropdown(parentElem, selector)
    listElem.dataset.requiresRefresh = 'false'
  }
}
