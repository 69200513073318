import './gated-handouts.css'

import Cookies from 'universal-cookie'
import addFlash from 'utils/addFlash'
import { removeFooterEmailForm } from '../footer-email-list/footer-email-list'
import emailListFormSetUp, { noTrackHideEvent, hideNewsletterCallbacks } from 'utils/emailListFormSetUp'

const selector = '.c-gated-handouts__email-modal'
const cookies = new Cookies()
const user_signed_in = !!gon.current_user_id

// the js is loaded async - after DOMContentLoaded has initialized)
// commented out for sem-minimal (because we want this to run even when
// document.addEventListener("DOMContentLoaded", () => {
$(() => {
  const container = document.querySelector(selector)
  if (!container) return
  if (shouldPopupBeHidden()) {
    unblurHandout()
    return
  }
  setUpModal(container)
  showModal(container)
})

const unblurHandout = () => {
  const handoutContainer = document.querySelector('.iframe-handout')
  if (!handoutContainer) return
  $(handoutContainer).css('filter', 'blur(0)')
}

const showModal = (container) => {
  $(container).modal()
}

const setUpModal = (container) => {
  $(container).on('hidden.bs.modal', () => hideNewsletterCallbacks(container))
  emailListFormSetUp(container, handleSuccess, handleFailure)
}

const handleFailure = (data, container) => {
  container.classList.add(noTrackHideEvent)
  $(container).modal('hide')
  addFlash(data.errorMessage, 'alert')
}

const handleSuccess = (data, container) => {
  container.classList.add(noTrackHideEvent)
  $(container).modal('toggle')
  unblurHandout()
  removeFooterEmailForm()
}

const shouldPopupBeHidden = () =>
  cookies.get('promoEmailRegistered') || document.querySelector('.application-body--v1--checkout') || user_signed_in
