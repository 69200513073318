import './panel-workshop.css'

import BadgerAccordion from 'badger-accordion'

$(() => {
  const elems = document.querySelectorAll('.js-c-panel-workshop__accordion')
  const accordions = []

  Array.from(elems).forEach((elem) => accordions.push(new BadgerAccordion(elem, { openHeadersOnLoad: [0] })))
})
