import './program-section-overview--single-page.css'

const displayedChildElements = 2

// document.addEventListener("DOMContentLoaded", function() {
$(() => {
  const containerSelector = '.c-program-section-overview--single-page'
  const container = document.querySelector(containerSelector)
  if (container === null) return

  const innerContainers = document.querySelectorAll('.c-accordion-item-card__panel-inner > :first-child')

  for (let i = 0; i < innerContainers.length; i++) {
    if (containsHiddenElements(innerContainers[i])) {
      const button = createButton(innerContainers[i])
      innerContainers[i].appendChild(button)
    }
  }
})

function containsHiddenElements(container) {
  return container.querySelectorAll('.program-section-class-details').length > displayedChildElements
}

function createButton(container) {
  const node = document.createElement('button')
  node.classList.add('program-section-class-details--read-more-cta')
  const textnode = document.createTextNode('Read More')
  node.appendChild(textnode)
  node.addEventListener('click', function () {
    const elems = container.querySelectorAll('.program-section-class-details')
    for (let i = 0; i < elems.length; i++) {
      elems[i].style.display = 'flex'
    }
    node.remove()
  })
  return node
}
