import './course-sections-toggle.css'

import BadgerAccordion from 'badger-accordion'

$(() => {
  const elems = document.querySelectorAll('.js-c-course-sections-toggle__accordion')
  const accordions = []

  let i = 0
  Array.from(elems).forEach((elem) => {
    const options = i == 0 ? { openHeadersOnLoad: [0] } : {}
    accordions.push(new BadgerAccordion(elem, options))
    i++
  })
})
