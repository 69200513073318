import './single-image-upload.css'

const readURLGeneric = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader()

    reader.onload = function (e) {
      $('.js-single-image-upload__original-image-preview').attr('src', e.target.result)
      $('.js-single-image-upload__original-image').css({
        display: 'none',
      })
      $('.js-single-image-upload__original-image-preview').css({
        display: 'inline-block',
      })
    }

    reader.readAsDataURL(input.files[0])
  }
}
document.addEventListener('DOMContentLoaded', function () {
  const container = document.querySelector('.js-single-image-upload')
  if (container === null) return
  $('.js-single-image-upload__original-image-field').change(function () {
    readURLGeneric(this)
  })
})
