import { HandoutOverlay } from './overlays/handout-overlay'
import { CourseView, CourseViewUpdater } from './course-view'
import { HandoutLesson } from './lessons/handout-lesson'

export class HandoutOverlayUpdater implements CourseViewUpdater {
  private overlay: HandoutOverlay

  constructor(overlay: HandoutOverlay) {
    this.overlay = overlay
  }

  run(courseView: CourseView, wasAutoplay?: boolean) {
    const lesson = courseView.getCurrentLesson()
    const nextLesson = courseView.getNextLesson()

    if (lesson instanceof HandoutLesson) {
      this.overlay.show(
        lesson.title,
        lesson.imageUrl,
        lesson.pdfUrl,
        !!wasAutoplay,
        nextLesson ? () => courseView.activate(nextLesson, true) : undefined
      )
    } else {
      this.overlay.hide()
    }
  }
}
