import './testimonials-landing-section.css'
import 'swiper/css/swiper.css'
import Swiper from 'swiper'

// document.addEventListener("DOMContentLoaded", function() {
$(() => {
  const swiper = new Swiper('.c-testimonials-landing-section .swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })
})
