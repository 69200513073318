$(function () {
  $('#child-info select').on('change', function () {
    $(this).attr('class', '').addClass('selected')
  })

  $('.birthday-select [name="month"]').change(function () {
    let month = $('.birthday-select [name="month"] option:selected').val()
    --month // have months start at 0 instead of 1
    $('.birthday-select [name="day"] option').show()
    if (month === 1) {
      $('.birthday-select [name="day"] option[value="30"]').hide()
      $('.birthday-select [name="day"] option[value="31"]').hide()
      if ($('.birthday-select [name="day"] option:selected').val() > 29) {
        $('.birthday-select [name="day"]').val(29).change()
      }
    } else if (/8|3|5|10/.test(month)) {
      $('.birthday-select [name="day"] option[value="31"]').hide()
      if ($('.birthday-select [name="day"] option:selected').val() > 30) {
        $('.birthday-select [name="day"]').val(30).change()
      }
    }
  })

  $('.birthday-select [name="year"]').change(function () {
    if (`${$('.birthday-select [name="day"] option:selected').val()}` === '29') {
      if (`${$('.birthday-select [name="month"] option:selected').val()}` === '2') {
        const year = $('.birthday-select [name="year"] option:selected').val()
        if ((year % 4 !== 0 || year % 100 === 0) && year % 400 !== 0) {
          $('.birthday-select [name="day"]').val(28).change()
        }
      }
    }
  })
})

$(function () {
  $('#kid_year').click(() => $('#kid_year').css('color', '#51535C'))
})

$(function () {
  if (!$('.signup-screen#child-info .add-child').length) {
    return
  }
  $('.add-child').click(function () {
    $('.new-kid').removeClass('hide')
    $('.select-kid').addClass('hide')
  })

  $('.selected-kid')
    .not('.add-child')
    .click(function () {
      const selected = $(this)
      const fill_form = () =>
        //gets the data
        new Promise(function (resolve, reject) {
          resolve($('#kid_id').val(selected.data('id')))
        })

      const submit_form = () =>
        //does some stuff with the data
        new Promise(function (resolve, reject) {
          resolve(selected.parents('form').attr('novalidate', 'novalidate').submit())
        })

      fill_form().then(submit_form)
    })
})
