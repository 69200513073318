import './modal-email-list.css'
import Cookies from 'universal-cookie'
import addFlash from 'utils/addFlash'
import { removeFooterEmailForm } from '../footer-email-list/footer-email-list'
import emailListFormSetUp, { noTrackHideEvent, hideNewsletterCallbacks } from 'utils/emailListFormSetUp'

import { emailConfirmDismissModal, emailConfirmSetUp } from 'components/email-confirm-modal/email-confirm-modal.js'

const selector = '.c-modal-email-list'
// const modalShowTime = 30 * 1000; /* 30 second delay */
const modalShowTime = 15 * 1000 /* 15 second delay test */
const shouldAutoDisplayModal = false
const cookies = new Cookies()

// the js is loaded async - after DOMContentLoaded has initialized)
// commented out for sem-minimal (because we want this to run even when
// document.addEventListener("DOMContentLoaded", () => {
window.setUpNewsletterModalOnExitIntent = () => {}
$(() => {
  const container = document.querySelector(selector)
  if (!container) return
  setUpModal(container)
  emailConfirmSetUp(container)
  setTimeout(() => {
    if (!shouldNewsletterBeShown()) return
    showModal(container)
  }, modalShowTime)

  // expose newsletter showing for exit intent a/b Google Optimize test
  if (!cookies.get('promoEmailRegistered')) {
    window.setUpNewsletterModalOnExitIntent = () => {
      let hasExitModalShown = false
      document.addEventListener('mouseout', (e) => {
        if (!hasExitModalShown && !e.toElement && !e.relatedTarget) {
          showModal(container)
          hasExitModalShown = true
        }
      })
    }
  }
})

const showModal = (container) => {
  emailConfirmDismissModal(container)
  $(container).modal()
}

const setUpModal = (container) => {
  $(container).on('hidden.bs.modal', () => hideNewsletterCallbacks(container))
  emailListFormSetUp(container, handleSuccess, handleFailure)
}

const handleFailure = (data, container) => {
  container.classList.add(noTrackHideEvent)
  $(container).modal('hide')
  addFlash(data.errorMessage, 'alert')
}

const handleSuccess = (data, container) => {
  container.classList.add(noTrackHideEvent)
  updateDOMSuccess(data, container)
  removeFooterEmailForm()
}

const updateDOMSuccess = (data, container) => {
  container.querySelector(`${selector}__code`).innerHTML = data.couponCode
  container.classList.add('c-modal-email-list--confirmed')
}

const shouldNewsletterBeShown = () => {
  if (!shouldAutoDisplayModal) {
    return false
  }
  return !(
    gon.noNewsletterModal === true ||
    cookies.get('hideNewsletterModal') ||
    cookies.get('promoEmailRegistered') ||
    document.querySelector('.application-body--v1--checkout')
  )
}
