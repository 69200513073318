import { VideoJsPlayer } from '@/definitions/videojs'

export interface MuxBrightcovePlayerProps {
  videoJsPlayer: VideoJsPlayer
  userId: any
  muxEnvKey: any
  doNotTrack?: boolean
}

export class MuxBrightcovePlayer {
  /*
    this player right now is always initialized without a source eventually
    make it so this player can get the current source if preloaded
  */
  player: VideoJsPlayer
  muxEnvKey: string
  currentSource: string | undefined
  listenerFunctions: any
  muxDataInitialized: boolean
  userId: string
  videoMetadata: any
  doNotTrack: boolean
  playerPromise?: Promise<boolean>

  constructor({ videoJsPlayer, userId, muxEnvKey, doNotTrack = false }: MuxBrightcovePlayerProps) {
    this.player = videoJsPlayer
    this.muxEnvKey = muxEnvKey
    this.currentSource = undefined
    this.listenerFunctions = []
    this.muxDataInitialized = false
    this.userId = userId
    this.videoMetadata = {}
    this.doNotTrack = doNotTrack
    if (this.player.src().length) this.initMuxData()

    // TIN-193 https://linear.app/tinyhood-dev/issue/TIN-193/the-video-speed-control-on-web-gets-resetlost
    // restore the original playbackRates when the player is reset to prevent the "play rate button" from disappearing
    const origPlaybackRates = this.player.playbackRates()
    this.player.on('playerreset', function (this: VideoJsPlayer) {
      this.playbackRates(origPlaybackRates)
    })
  }

  setMuxData() {
    if (this.doNotTrack) return
    if (this.muxDataInitialized) {
      this.muxDataSourceChange()
    } else {
      this.initMuxData()
    }
  }

  muxDataSourceChange() {
    this.player.mux.emit('videochange', {
      video_id: this.videoMetadata.videoId,
      video_title: this.videoMetadata.videoTitle,
    })
  }

  initMuxData() {
    const playerInitTime = Date.now()
    this.player.mux({
      debug: false,
      data: {
        env_key: this.muxEnvKey,

        // Site Metadata
        viewer_user_id: this.userId,

        // Player Metadata
        player_name: 'Tinyhood Web Player',
        player_init_time: playerInitTime,

        // Video Metadata (cleared with 'videochange' event)
        video_id: this.videoMetadata.videoId,
        video_title: this.videoMetadata.videoTitle,
      },
    })
    this.muxDataInitialized = true
  }

  getCurrentSource() {
    return this.currentSource
  }

  changeSrc(source: string, sourceType = 'application/x-mpegURL', metadata = {}) {
    // Playback rate will get reset on source change, so remember the current playback rate now
    // and we'll re-set it when the player is ready to play the next file
    // (https://github.com/videojs/video.js/issues/5128#issuecomment-385325253)
    const lastPlaybackRate = this.player.playbackRate()

    this.videoMetadata = metadata
    this.removeListeners()
    this.currentSource = source
    this.player.src({
      type: sourceType,
      src: source,
    })
    this.setMuxData()

    this.player.ready(() => {
      this.player.playbackRate(lastPlaybackRate)
    })
  }

  play() {
    this.player.ready(() => {
      this.playerPromise = this.player.play()
    })
  }

  togglePlayback() {
    if (this.isPlaying()) {
      this.pause()
    } else {
      this.play()
    }
  }

  pause() {
    this.player.ready(() => {
      // See https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      // for reasoning behind playerPromise usage.
      if (this.playerPromise !== undefined) {
        this.playerPromise.then(() => this.player.pause())
      }
    })
  }

  isPlaying() {
    return !this.player.paused()
  }

  duration() {
    return this.player.duration()
  }

  addListener(event: any, fn: () => void) {
    this.listenerFunctions.push([event, fn])
    this.player.on(event, fn)
  }

  removeListeners() {
    for (let i = 0; i < this.listenerFunctions.length; i++) {
      this.player.off(this.listenerFunctions[i][0], this.listenerFunctions[i][1])
    }
    this.listenerFunctions = []
  }

  currentTime() {
    return this.player.currentTime()
  }

  setCurrentTime(timeInSeconds: number) {
    this.player.currentTime(timeInSeconds)
  }

  reset() {
    this.player.reset()
  }
}
