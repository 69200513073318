import { isClassReviewPromptDisabled } from '@/components/class-review-prompt/class-review-prompt'
import { CourseView, CourseViewUpdater } from './course-view'
import { ReviewPrompt } from './review-prompt'

export class ReviewPromptUpdater implements CourseViewUpdater {
  private prompt: ReviewPrompt

  constructor(prompt: ReviewPrompt) {
    this.prompt = prompt
  }

  run(courseView: CourseView) {
    const workshop = courseView.getCurrentWorkshop()
    if (
      workshop.getIsReviewable() &&
      courseView.onLastVideoLessonInWorkshop() &&
      !isClassReviewPromptDisabled(workshop.id)
    ) {
      this.prompt.setWorkshop(workshop.id, 'RecordedWorkshop')
      this.prompt.show()
    } else {
      this.prompt.hide()
    }
  }
}
