import './program-section-drawers-unpaid.css'

import BadgerAccordion from 'badger-accordion'

$(() => {
  const elems = document.querySelectorAll('.js-c-program-section-drawers-unpaid__accordion')
  const accordions = []

  Array.from(elems).forEach((elem, index) => {
    accordions.push(new BadgerAccordion(elem))
  })
})
