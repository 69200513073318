// depends on jQuery
$(document).ready(function () {
  $(window).on('shown.bs.modal', function () {
    $('html').addClass('modal-open')
  })

  $(window).on('hidden.bs.modal', function () {
    $('html').removeClass('modal-open')
  })
})
