// depends on jQuery
$(function () {
  if ($('.experts-index').length) {
    $('a.deleteExpert').on('click', function (event) {
      const button = $(this)
      const expertLink = button.data('link')
      const expertName = button.data('name')
      const modal = $('#deleteExpert')
      modal.find('.modal-title').text('Remove ' + expertName + "'s Expert Profile")
      modal.find('.btn-danger').text('Yes, remove ' + expertName)
      modal.find('.btn-danger').attr('href', expertLink)
      modal.modal()
    })
  }
})
