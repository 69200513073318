import './footer-email-list.css'
import emailListFormSetUp from 'utils/emailListFormSetUp'
import { emailConfirmSetUp } from 'components/email-confirm-modal/email-confirm-modal.js'

const selector = '.c-footer-email-list'
const parentSelector = '.footer-signed-out-v1__sign-up'

// document.addEventListener("DOMContentLoaded", () => {
$(() => {
  const container = document.querySelector(selector)
  if (container) {
    emailListFormSetUp(container, handleSuccess, handleFailure)
    emailConfirmSetUp(container)
  }
})

const handleFailure = (data, _container) => {
  document.querySelector(`${parentSelector} .start-hidden`).innerHTML = data.errorMessage
  document.querySelector(parentSelector).classList.add('show-hidden')
}

const handleSuccess = (data, _container) => {
  document.querySelector(parentSelector).classList.add('show-hidden')
}

export const removeFooterEmailForm = () => {
  const classesToRemove = ['footer-signed-out-v1--email-sign-up', 'footer-email-list-shown']
  /* reset spacing for absence of email form */
  removeClasses(classesToRemove)

  const footerContainer = document.querySelector(parentSelector)
  footerContainer.parentNode.removeChild(footerContainer)
}

const removeClasses = (classes) => {
  for (let i = 0; i < classes.length; i++) {
    const elem = document.querySelector(`.${classes[i]}`)
    if (elem) elem.classList.remove(classes[i])
  }
}
