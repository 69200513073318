import './step-list-interactable-unpaid.css'

import { smoothScroll } from 'utils/smoothScroll.js'
import { BrightcoveModalDialog } from 'components/brightcove-modal-dialog/brightcove-modal-dialog.js'

const containerClass = 'c-step-list-interactable-unpaid'
const stepClass = `${containerClass}__step`
const selectedStepClass = `${stepClass}--selected`
const playableStepClass = `${stepClass}--playable`
const unplayableStepClass = `${stepClass}--unplayable`
const playingStepClass = `${stepClass}--playing`

export const getDefaultPoster = () => {
  const firstPlayableChapter = document.querySelector(
    `.${playableStepClass}:first-of-type .${containerClass}__step-link`
  )
  if (firstPlayableChapter && !!firstPlayableChapter.dataset.poster) {
    return firstPlayableChapter.dataset.poster
  } else {
    return null
  }
}

export const getDefaultSrc = () => {
  const firstPlayableChapter = document.querySelector(
    `.${playableStepClass}:first-of-type .${containerClass}__step-link`
  )
  if (firstPlayableChapter) {
    return {
      type: firstPlayableChapter.dataset.sourceType,
      src: firstPlayableChapter.dataset.sourceUrl,
    }
  } else {
    return null
  }
}

// Don't love this code - can definitely be improved in future
// think about using some of the existing typescript video code
// The logic is a little funky because this component is rendered twice
// on the screen (once for use in desktop, other in mobile)
export class LockedChapterView {
  constructor(player, overlayContent) {
    this.player = player
    this.overlayContent = overlayContent
    this.modalDialog = new BrightcoveModalDialog(player, '.locked-chapter-modal')
    this.currentElem = null

    this.setupClickedChapters()
    this.enableCustomEndScreen()
    this.setupPlayerEvents()
  }

  enableCustomEndScreen() {
    this.player.on('ended', () => {
      this.modalDialog.modal.open()
      this.displayExitFullscreenButton(this.player)
    })
  }

  setupPlayerEvents() {
    this.player.on('pause', () => {
      this.setChapterPlayingClass(this.currentElem, false)
    })
    this.player.on('play', () => {
      this.setChapterPlayingClass(this.currentElem, true)
    })
  }

  displayExitFullscreenButton() {
    if (this.player.isFullscreen()) {
      $('.close-overlay').css('display', 'block')
      $('.close-overlay').click(() => {
        this.player.exitFullscreen()
        $('.close-overlay').css('display', 'none')
      })
    }
  }

  highlightChapter(stepElem) {
    const chapters = document.querySelectorAll(`.${stepClass}`)
    for (var i = 0; i < chapters.length; i++) {
      if (this.isSameStep(chapters[i], stepElem)) stepElem.classList.add(selectedStepClass)
      else {
        chapters[i].classList.remove(selectedStepClass)
        chapters[i].classList.remove(playingStepClass)
      }
    }
  }

  setChapterPlayingClass(stepElem, shouldAdd) {
    const chapters = document.querySelectorAll(`.${stepClass}`)
    for (var i = 0; i < chapters.length; i++) {
      if (this.isSameStep(chapters[i], stepElem)) {
        if (shouldAdd) {
          chapters[i].classList.add(playingStepClass)
        } else {
          chapters[i].classList.remove(playingStepClass)
        }
      }
    }
  }

  isSameStep(target, elem) {
    if (target === null || elem === null) return false
    return elem.dataset.id === target.dataset.id
  }

  setupClickedChapters() {
    this.setupPlayableChapters()
    this.setupLockedChapter()
    this.highlightDefaultChapter()
  }

  highlightDefaultChapter() {
    const chapters = document.querySelectorAll(`.${playableStepClass}:first-of-type`)
    for (var i = 0; i < chapters.length; i++) {
      this.highlightChapter(chapters[i])
    }
  }

  getElemSrc(stepElem) {
    const targetDataElem = stepElem.querySelector(`.${containerClass}__step-link`)
    return {
      type: targetDataElem.dataset.sourceType,
      src: targetDataElem.dataset.sourceUrl,
    }
  }

  setupPlayableChapters() {
    const chapters = document.querySelectorAll(`.${playableStepClass}`)
    for (var i = 0; i < chapters.length; i++) {
      const chapter = chapters[i]
      $(chapter).click((_event) => {
        if (this.modalDialog.modal.opened()) this.modalDialog.modal.close()

        if (!this.isSameStep(this.currentElem, chapter)) {
          this.player.pause()
          this.player.src(this.getElemSrc(chapter))
          this.currentElem = chapter
        }

        this.standardClickedChapterEvents(chapter)

        if (this.player.paused()) {
          this.player.play()
        } else {
          this.player.pause()
        }
      })
    }
  }

  setupLockedChapter() {
    const chapters = document.querySelectorAll(`.${unplayableStepClass}`)
    for (var i = 0; i < chapters.length; i++) {
      const chapter = chapters[i]
      $(chapter).click((_event) => {
        this.modalDialog.modal.open()
        this.player.pause()
        this.standardClickedChapterEvents(chapter)
      })
    }
  }

  standardClickedChapterEvents(chapter) {
    this.highlightChapter(chapter)
    smoothScroll('.recorded-workshop-header__c-video')
  }
}
