import 'swiper/css/swiper.css'
import './testimonials-cards-section.css'
import Swiper from 'swiper'

document.addEventListener('DOMContentLoaded', function () {
  const _swiper = new Swiper('.c-testimonials-cards-section .swiper-container', {
    slidesPerView: 1,
    spaceBetween: 24,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1000: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 3,
      },
    },
  })
})
