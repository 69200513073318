// relies on jQuery
$(function () {
  if ($('.js-moderator-apply-select').length) {
    $('.js-moderator-apply-select').change(function () {
      const $selected = $(this).find(':selected')
      $('.apply-type').val($selected.val())
      $('.moderator-table-form').attr('action', $selected.data('url')).submit()
    })
  }

  if ($('.select-all:checkbox').length) {
    $('.select-all:checkbox').click(function () {
      $(this).closest('table').find(':checkbox').prop('checked', this.checked)
    })
  }

  if ($('.js-excel-export').length) {
    $('.js-excel-export').click(function () {
      $('#excel-options-modal').modal()
    })
  }
  if ($('.js-invite-options').length) {
    $('.js-invite-options').click(function () {
      $('#invite-options-modal').modal()
    })
  }
})

$(function () {
  if ($('.moderator-export-form').length) {
    $('.moderator-export-form').submit(function (event) {
      // If the custom date range option is selected, you must specify both a
      // start and end date for the range
      if (
        $('#age_range_custom').is(':checked') &&
        ($('#from_birthdate').val() === '' || $('#to_birthdate').val() === '')
      ) {
        return false
      }
    })
  }
})

$(() => $('#from_birthdate').focus(() => $('#age_range_custom').prop('checked', true)))

$(() => $('#to_birthdate').focus(() => $('#age_range_custom').prop('checked', true)))
