/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Spinner } from 'spin.js'

// Listener specific to the dropdown being opened. General styling/actions
// for opening the dropdown also occur in _header.coffee
$(() =>
  $('.dropdown-menu.messages').on('show.headerMenu', function () {
    if (document.getElementById('required-name-modal')) {
      $('#required-name-modal').modal({
        backdrop: 'static',
        keyboard: false,
      })
    }
    showUserMessages()
    return $('#message-form').submit()
  })
)

const showLoadedMessages = function (messageHtml) {
  const $messageHtml = $(messageHtml)
  $('.messaging-spinner').remove()
  $('#user-message-index .next').remove()
  const $dropdownContainer = $('#user-message-index')
  $($dropdownContainer).append($messageHtml)
  $('html, body').animate(
    () => ({
      scrollTop: $messageHtml.offset().top,
    }),
    500
  )
}
window.showLoadedMessages = function (html) {
  showLoadedMessages(html)
}

const loadMessages = function () {
  const url = '/conversations/previews'
  $.ajax({ url })
    .done(showLoadedMessages)
    .always(function () {
      $('#unread-message-count').hide()
    })
}
window.loadMessages = function () {
  loadMessages()
}

const showSpinner = function (parentElement) {
  parentElement.html("<li class='messaging-spinner'></li>")
  const card = parentElement.find('li.messaging-spinner')
  const spinner = new Spinner({
    radius: 9,
    lines: 11,
    width: 4,
    color: '#4B6A8A',
  }).spin()
  card.html(spinner.el)
}

var showUserMessages = function () {
  const dropdown = $('#header-msg-list')
  const requiresRefresh = dropdown.data('requiresRefresh')
  if (requiresRefresh) {
    showSpinner($('#user-message-index'))
    loadMessages()
  }
  dropdown.data('requiresRefresh', false)
}
