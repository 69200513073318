import './course-sections-toggle-alt.css'

import BadgerAccordion from 'badger-accordion'

$(() => {
  const elems = document.querySelectorAll('.js-c-course-sections-toggle-alt__accordion')
  const accordions = []

  Array.from(elems).forEach((elem) => {
    accordions.push(new BadgerAccordion(elem))
  })
})
