import './faq-section.css'

import BadgerAccordion from 'badger-accordion'

$(() => {
  const container = document.querySelector('.c-faq-section')
  const elems = document.querySelectorAll('.js-c-faq-section__accordion')
  const accordions = []

  if (!container) {
    return
  }

  Array.from(elems).forEach((elem) => {
    accordions.push(new BadgerAccordion(elem))
  })
})
