import './pagy-nav-show-more.css'

/* this needs to be refactored if it's to be used more widely */
/* Right now only applies to workshop reviews */

// commented out for sem-minimal (because we want this to run even when
// the js is loaded async - after DOMContentLoaded has initialized)
// document.addEventListener("DOMContentLoaded", () => {
$(() => {
  const containerSelector = '.c-pagy-nav-show-more'
  const container = document.querySelector(containerSelector)
  if (container === null) return

  const pagyNav = new PagyNavShowMore(container, pagyDataset(container), container.dataset.url)

  container.querySelector('button').addEventListener('click', function () {
    pagyNav.showNextPage()
  })
})

function pagyDataset(elem) {
  const pagy = JSON.parse(elem.dataset.pagy)
  pagy.page = parseInt(pagy.page)
  Object.assign(pagy.params, JSON.parse(elem.dataset.additionalParams))
  return pagy
}

class PagyNavShowMore {
  constructor(container, data, url) {
    this.container = container
    this.data = data
    this.url = url
  }

  showNextPage() {
    const queryObject = Object.assign({}, this.data.params, {
      page: this.data.page + 1,
    })
    const queryString = new URLSearchParams(queryObject).toString()

    fetch(`${this.url}?${queryString}`)
      .then((response) => response.text())
      .then((data) => {
        const elem = document.createElement('div')
        elem.innerHTML = data
        this.showReviews(elem.querySelectorAll('.c-review-item'))
        this.updatePagination(elem.querySelector('.c-pagy-nav-show-more'))
      })
  }

  updatePagination(pagyElem) {
    if (pagyElem) {
      this.data = pagyDataset(pagyElem)
      this.container.querySelector('button').innerHTML = pagyElem.querySelector('button').innerHTML
    } else {
      this.container.remove()
    }
  }

  showReviews(reviews) {
    for (let i = 0; i < reviews.length; i++) {
      this.container.previousElementSibling.appendChild(reviews[i])
    }
  }
}
