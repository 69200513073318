$(function () {
  if ($('.system-promo-video').length) {
    // vimeo default ratio is 16:9 - leave the div's width and adjust the height
    const $video_divs = $('.system-promo-video')
    $video_divs.each(function (index) {
      const height = $(this).width() / $(this).height() < 16 / 9.0 ? ($(this).width() * 9) / 16.0 : $(this).height()
      if (height < $(this).height()) {
        $(this).css('height', height)
      }
      const options = {
        id: $(this).data('video-id'),
        width: $(this).width(),
        height,
        playsinline: false,
      }

      const videoPlayer = new Vimeo.Player($(this).attr('id'), options)
    })
  }
})

$(function () {
  if ($('.system-promo-form').length) {
    $('.js-media-type-select :radio').change(function () {
      const klass = $(this).data('toggle-class')
      $(`.${klass}`).siblings().addClass('hide')
      $(`.${klass}`).removeClass('hide')
      if ($('.js-video-section-toggle').is(':checked')) {
        $('.js-link-preview-video-information').removeClass('force-hide')
      } else {
        $('.js-link-preview-video-information').addClass('force-hide')
      }
    })
  }
})
