const addFlash = (message, type) => {
  const flashDiv = $('<div>', { class: 'flashmessage' })
  flashDiv.append($(`<div>${message}</div>`).addClass(`alert alert-${type}`)).css('display', 'none')

  $('.flashmessage').fadeOut(300)
  if ($('body > #header, body > .js-nav-header').length) {
    $(flashDiv).insertAfter('body > #header, body > .js-nav-header').fadeIn(300)
  } else {
    $(flashDiv).insertAfter('body').fadeIn(300)
  }
  $('.flashmessage').delay(6000).fadeOut(800)
}

export default addFlash
