import './modal-video-section.css'
import { VideoPlayer } from '@/utils/course-view/video-player'

const BASE_SELECTOR = '.c-modal-video-section'
const MODAL_SELECTOR = `${BASE_SELECTOR}--modal`
const BACKDROP_SELECTOR = `${BASE_SELECTOR}--backdrop`
const VIDEO_PLAYER_SELECTOR = `${MODAL_SELECTOR}__videojs`
const OPEN_MODAL_LINK_SELECTOR = `${BASE_SELECTOR}__subtitle-container`

document.addEventListener('DOMContentLoaded', function () {
  const containerEl = document.querySelector(BASE_SELECTOR)
  const openModalLinkEl = document.querySelector(OPEN_MODAL_LINK_SELECTOR)
  const videoPlayerEl = document.querySelector(VIDEO_PLAYER_SELECTOR)

  if (!containerEl || !openModalLinkEl || !videoPlayerEl) {
    return
  }

  const player = createVideoPlayer(videoPlayerEl)
  const muxId = videoPlayerEl.dataset.muxId
  const videoId = videoPlayerEl.dataset.videoId
  const videoTitle = videoPlayerEl.dataset.videoTitle
  setVideoPlayerVideo(player, muxId, videoId, videoTitle)

  openModalLinkEl.addEventListener('click', function () {
    $(MODAL_SELECTOR).modal({ backdrop: 'static' })
    document.querySelector(BACKDROP_SELECTOR).style.display = 'block'
    player.play()
  })

  $(MODAL_SELECTOR).on('hidden.bs.modal', function () {
    player.pause()
    player.setCurrentTime(0)
    document.querySelector(BACKDROP_SELECTOR).style.display = 'none'
  })
})

function createVideoPlayer(videoPlayerElement) {
  return new VideoPlayer({
    videoJsPlayer: videojs(videoPlayerElement),
    muxEnvKey: gon.muxEnvKey,
    userId: gon.userId,
  })
}

function setVideoPlayerVideo(videoPlayer, muxId, videoId, videoTitle) {
  videoPlayer.setVideo({
    video: {
      sourceUrl: `https://stream.mux.com/${muxId}.m3u8`,
      sourceType: 'application/x-mpegURL',
      id: videoId,
      title: videoTitle,
    },
    shouldAutoplay: false,
    onProgress: () => null,
    onEnd: () => null,
    onPause: () => null,
    onPlay: () => null,
  })
}
