import './course-section-previews.css'

import { addPlayer, getPlayer } from 'components/video-modal--brightcove/video-modal--brightcove.js'

document.addEventListener('DOMContentLoaded', function () {
  return /* direct user straight to class page */
  const containerSelector = '.c-course-section-previews__preview--visible'
  const containers = document.querySelectorAll(containerSelector)
  for (let i = 0; i < containers.length; i++) {
    containers[i].addEventListener('click', (event) => {
      addPlayer(JSON.parse(event.currentTarget.dataset.brightcoveArgs), event.currentTarget.dataset.title)
      document.querySelector('.c-video-modal--brightcove').classList.remove('hidden')
      $('.c-video-modal--brightcove').on('hidden.bs.modal', function () {
        const player = getPlayer()
        if (player) player.pause()
      })
      $('.c-video-modal--brightcove').modal()
    })
  }
})
