/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { smoothScroll } from 'utils/smoothScroll.js'

$(function () {
  $('#community-landing-page .js-learn-more').click(function () {
    smoothScroll('.hero-footer')
  })

  $('#community-landing-page .js-early-access-btn').click(function () {
    $('html, body').animate(
      {
        scrollTop: $('#community-landing-page #circle-interest-form').offset().top - $('#header').height(),
      },
      1000
    )
  })
})

$(function () {
  // If in mobile, we hide any list items after the fourth
  if ($('#circle-search .group-results li').length > 4 && /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    $('#circle-search .group-results li:nth-child(n+5)').css('display', 'none')
    $('#circle-search .load-more-groups').css('display', 'block')
  } else if ($('#circle-search .group-results li').length > 6) {
    // In desktop hide any after the sixth
    $('#circle-search .group-results li:nth-child(n+7)').css('display', 'none')
    $('#circle-search .load-more-groups').css('display', 'block')
  }
  $('#circle-search .load-more-groups').click(function () {
    $('#circle-search .group-results li').slideDown(500)
    $('#circle-search .load-more-groups').css('display', 'none')
  })
})
