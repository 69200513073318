/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  // Only want to show the stop recurring date field if the recurring field is
  // not null
  if ($('.new_calendar_event').length || $('.edit_calendar_event').length) {
    // show field correctly on initial page load
    if ($('select.recurring_select').val() !== 'null') {
      $('.js-stop-recurring-field').css('display', 'block')
    } else {
      $('.js-stop-recurring-field').css('display', 'none')
    }
    // show field correctly on recurring field change
    $('select.recurring_select').change(function () {
      // Recurring select sets this as "null" instead of nil
      if ($(this).val() !== 'null') {
        $('.js-stop-recurring-field').css('display', 'block')
      } else {
        $('.js-stop-recurring-field').css('display', 'none')
      }
    })
  }
})

$(function () {
  if ($('.edit_calendar_event').length && gon.isRecurringEvent) {
    $('#calendar_event_recurring').prop('disabled', 'true')
  }
})

const recurringDialogSelector = '.rs_dialog a[title="Cancel"], .rs_dialog .rs_cancel'
$(document).on('click', recurringDialogSelector, function () {
  if ($('select.recurring_select').val() !== 'null') {
    $('.js-stop-recurring-field').css('display', 'block')
  } else {
    $('.js-stop-recurring-field').css('display', 'none')
  }
})

$(function () {
  // This is for previewing an attached image
  const readURLCalendarEvents = function (input) {
    if (input.files && input.files[0]) {
      const reader = new FileReader()

      reader.onload = function (e) {
        $('#calendar_event_image_preview').attr('src', e.target.result)
        $('#original_calendar_event_image').css({ display: 'none' })
        $('#calendar_event_image_preview').css({ display: 'inline-block' })
      }

      reader.readAsDataURL(input.files[0])
    }
  }

  return $('#calendar_event_image').change(function () {
    readURLCalendarEvents(this)
  })
})

$(function () {
  if ($('.rsvp .js-rsvp-button').length && $('.js-event-rsvp-form').length) {
    $('.rsvp .js-rsvp-button').click(function () {
      $('.js-event-rsvp-form').show('400')
      $(this).hide()
    })
  }
})

const ready = function () {
  $('#calendar_event_address_attributes_country_id').change()
}

$(document).ready(ready)
$(document).on('page:load', ready)
