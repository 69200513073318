import './program-section-body.css'

document.addEventListener('DOMContentLoaded', function () {
  const containerSelector = '.c-program-section-body__header-expert'
  const container = document.querySelector(containerSelector)
  if (container === null) return
  container.querySelector('a').addEventListener('click', (e) => {
    e.preventDefault()
    container.querySelector('.hidden').classList.remove('hidden')
    e.currentTarget.classList.add('hidden')
  })
})
