import getValidatedRecaptcha from './recaptcha-widget.js'
import addFlash from 'utils/addFlash'

const delayResubmitTime = 0.5 * 1000 /* half a second */
const EXPERT_SIGNUP = 'expert_signup'
const COMMUNITY = 'Community'

$(() => {
  const container = document.querySelector('.recaptcha-form-container')
  if (!container) return
  const form = container.querySelector('.recaptcha-form')
  form.addEventListener('submit', function (event) {
    event.preventDefault()
    const recaptcha_token = getValidatedRecaptcha()

    if (!recaptcha_token) {
      enableResubmit(container)
      return handleFailure('Failed to authenticate reCAPTCHA, please refresh the page and try again.')
    }
    fetch(`${form.action}.json`, {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
      body: JSON.stringify(formData(form, recaptcha_token)),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          handleSuccess(data)
        } else handleFailure(data.errorMessage)
      })
    return true
  })
})

const enableResubmit = (container) => {
  const submissionBtn = container.querySelector('.btn-cta')
  setTimeout(() => {
    submissionBtn.removeAttribute('disabled')
  }, delayResubmitTime)
}

const formData = (form, recaptcha_token) => {
  const form_label = form.querySelector("[name='email_list[label]']").value
  if (form_label == EXPERT_SIGNUP) {
    return expertSignupFormData(form, recaptcha_token)
  }
  if (form_label == COMMUNITY) {
    return circleCommunityFormData(form, recaptcha_token)
  }

  return false
}

const expertSignupFormData = (form, recaptcha_token) => {
  return {
    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
    email_list: {
      label: form.querySelector("[name='email_list[label]']").value,
      first_name: form.querySelector("[name='email_list[first_name]']").value,
      email: form.querySelector("[name='email_list[email]']").value,
      topics_text: form.querySelector("[name='email_list[topics_text]']").value,
      description: form.querySelector("[name='email_list[description]']").value,
    },
    recaptcha_response: recaptcha_token,
  }
}

const circleCommunityFormData = (form, recaptcha_token) => {
  var user_id_field = form.querySelector("[name='email_list[user_id]']")
  if (user_id_field != null) {
    user_id_field = user_id_field.value
  }
  return {
    authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
    email_list: {
      label: form.querySelector("[name='email_list[label]']").value,
      sign_up_url: form.querySelector("[name='email_list[sign_up_url]']").value,
      user_id: user_id_field,
      first_name: form.querySelector("[name='email_list[first_name]']").value,
      email: form.querySelector("[name='email_list[email]']").value,
    },
    recaptcha_response: recaptcha_token,
  }
}

const handleFailure = (errorMessage) => {
  addFlash(errorMessage, 'alert')
  return false
}

const handleSuccess = (data) => {
  if (data.label == COMMUNITY) {
    return circleCommunitySuccess()
  }
  return false
}

const circleCommunitySuccess = () => {
  $('#circle-confirmation #modal-circle-early-access-confirmation-text').text('Thank you!')
  $('#circle-confirmation #confirmation-text-section-one').text(
    'A Tinyhood Representative will reach out to you shortly.'
  )
  $('.recaptcha-form-container').hide()
  $('#circle-confirmation').modal('show')
}
