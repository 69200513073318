$(function () {
  if ($('.signup-screen').length) {
    $('.change-to-sign-up').click(function () {
      $('.modal.sign_in').modal('hide')
      return false
    })
  }
})

$(function () {
  if ($('body').hasClass('signed-out')) {
    initializeSignUpModals()
  }
})

var initializeSignUpModals = function () {
  buttonListeners('sign-in', 'sign-up')
  buttonListeners('sign-up', 'sign-in')
  $('#show-password').click(function () {
    if ($('#show-password').is(':checked')) {
      $('#sign-up-modal-content [name="user[password]"').attr('type', 'text')
    } else {
      $('#sign-up-modal-content [name="user[password]"').attr('type', 'password')
    }
  })
}

var buttonListeners = function (primaryType, otherType) {
  signInUpButtonListeners(primaryType, otherType)
  switchContentListeners(primaryType, otherType)
}

// sign-in-link link still used in a handfull of places
var signInUpButtonListeners = (primaryType, otherType) =>
  $(`.${primaryType}-link`).click(function (event) {
    event.preventDefault()
    if ($('#hamburger-menu').length) {
      $('#hamburger-menu').removeClass('in')
    }
    $(`#${primaryType}-modal-content`).css('display', 'block')
    $(`#${otherType}-modal-content`).css('display', 'none')
    $('#sign-in-modal-container .modal').on('shown.bs.modal', () =>
      $(`#${primaryType}-modal-content #user_name`).focus()
    )
    $('#sign-in-modal-container .modal').modal()
  })

var switchContentListeners = (primaryType, otherType) =>
  $('#sign-in-modal-container').on('click', `.change-to-${primaryType}`, function (event) {
    event.preventDefault()
    $(`#${primaryType}-modal-content`).css('display', 'block')
    $(`#${otherType}-modal-content`).css('display', 'none')
    // not super happy about this, but the focus won't stay unless it's delayed
    setTimeout(() => $(`#${primaryType}-modal-content [name='user[email]']`).focus(), 300)
  })
