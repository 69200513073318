import './panel-step.css'

$(() => {
  const elems = document.querySelectorAll('.c-panel-step')
  Array.from(elems).forEach((elem) => {
    if (elem.dataset.isComplete === '1') {
      elem.classList.add('c-panel-step--completed')
    }
  })
})
