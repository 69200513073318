/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Cookies from 'universal-cookie'
const cookies = new Cookies()

$(function () {
  // Hide stripe field if price is 0
  if ($(".simple-price[data-price='0']").length) {
    $('.card-element').remove() // remove stripe field
    const selector = ".simple-price[data-price='0'] + .payment-section"
    $('.c-stripe-form__price').val(0)
    $(selector).addClass('hide-payment')
    $('.coupon-link, .coupon-lookup').css('display', 'none')
  }
})

$(function () {
  // Don't display coupon indicator if a user is signed in because we don't
  // want to show it for users that have already signed up with a coupon and
  // are just going through the question flow in order to chat with another
  // expert.
  if ($('#child-info-header-bar').length && !window.user_signed_in) {
    let coupon_code
    if ((coupon_code = cookies.get('coupon_code') && !$('#coupon-section').length)) {
      const divText =
        '<div class="alert alert-success coupon-indicator"' +
        ' id="coupon-section">Using coupon: ' +
        coupon_code +
        '</div>'
      $(divText).insertAfter('#child-info-header-bar')
    }
  }
})

$(function () {
  $('.coupon-link').click(function () {
    $(this).addClass('selected')
    $(this).next('form').css('display', 'block')
  })
  $('.coupon-lookup').submit(function () {
    const $form = $(this)
    const code = $form.find('input[type="text"]').val().toUpperCase()
    const parameters = setUpForm($form, code)
    $.get(`/coupons/discount.json?${parameters}`, function (data) {
      $form.find('input[type="submit"]').attr('disabled', false)
      if (typeof data.error !== 'undefined') {
        $form.find('.error-msg').append(data.error)
        showPayment($form)
      } else if (typeof data.amount !== 'undefined') {
        const basePrice = parseFloat($('.simple-price').data('price'))
        if (data.type === 'gift') {
          applyGiftCard($form, code, data)
        } else if (basePrice < data.amount) {
          applyCoupon(basePrice, $form, code, data.type, basePrice)
        } else {
          applyCoupon(data.amount, $form, code, data.type, basePrice)
        }
      }
    })
    return false
  })

  var setUpForm = function ($form, code) {
    let price_param
    $form.find('input[type="submit"]').attr('disabled', true)
    $form.find('.error-msg, .success-msg').empty()
    const price = $form.siblings('.simple-price').data('price') || $form.find('#price').val()
    if (price) {
      price_param = `&price=${price}`
    } else {
      price_param = ''
    }
    cookies.remove('coupon_code', { path: '/' })
    return `code=${code}${price_param}`
  }

  const hidePayment = function ($form) {
    const text = $('.workshop-payments').length ? 'Confirm Payment' : 'Chat Now'
    $form
      .siblings('.payment-section')
      .addClass('hide-payment')
      .find('input[type="submit"]')
      .val(text)
      .parentsUntil('.payment-section')
      .css('display', 'block')

    $('.c-stripe-form__price').val(0)
  }
  var showPayment = function ($form) {
    $form.siblings('.payment-section').removeClass('hide-payment').find('input[type="submit"]').val('Confirm Payment')
  }

  const couponMessage = (type, code, amount) =>
    `You are saving $${amount.toFixed(2)} with the ${code}` + ` ${type} code.`

  var applyGiftCard = function ($form, code, data) {
    $form.find('[name="code"]').val('')
    $form.siblings('.expanded-price .credit').text(data.creditText)
    $form.siblings('.simple-price').data('price', data.basePrice)
    updatePrice($form, 0)
  }

  var applyCoupon = function (amount, $form, code, type, basePrice) {
    $form.find('.success-msg').append(couponMessage(type, code, amount)).append(resetCouponLink(basePrice, $form))
    cookies.set('coupon_code', code, { path: '/' })
    if (document.querySelector('.c-stripe-form__code, .c-amazon-pay-form__code')) {
      $('.c-stripe-form__code, .c-amazon-pay-form__code').val(code)
    }
    updatePrice($form, amount)
  }

  var resetCouponLink = function (basePrice, $form) {
    const $resetCoupon = $('<span class="remove-link">Remove</span>')
    $resetCoupon.click(function () {
      if ($form.siblings('.js-simple-price').text().trim() === `$${basePrice}`) {
        $form.siblings('.js-simple-price').css('display', 'block')
        $form.siblings('.js-expanded-price').css('display', 'none')
      } else {
        $form.siblings('.js-expanded-price').find('.new-price').html(`$${basePrice}`)
      }

      showPayment($form)
      cookies.remove('coupon_code', { path: '/' })
      $form.find('.success-msg').empty()
    })
    return $resetCoupon
  }

  var updatePrice = function ($form, amount) {
    const newPrice = $('.simple-price').data('price') - amount.toFixed(2)
    if (newPrice === 0) {
      hidePayment($form)
    } else {
      showPayment($form)
    }
    const $priceDiv = $form.siblings('.expanded-price')
    const priceText = newPrice % 1 === 0 ? newPrice : newPrice.toFixed(2)
    $priceDiv.find('.new-price').html(`$${priceText}`)
    $priceDiv.siblings('.simple-price').css('display', 'none')
    $priceDiv.css('display', 'block')
  }
})

$(function () {
  const coupon_stored =
    $('.js-coupon-lookup input[type="text"]').length && $('.js-coupon-lookup input[type="text"]').val().length
  if (coupon_stored) {
    $('.coupon-link').click()
    $('.js-coupon-lookup').submit()
  }
})
