// relies on jQuery
$(function () {
  // We want to hide the caregiver search tag option unless the JPMoms subgroup
  // "Childcare, Preschools and Camps" is selected.
  // This class .js-hide-caregiver-search-tag is applied to the post dropdown
  // options of JPMoms and all of its subgroups except for "Childcare, Preschools
  // and Camps".
  if ($('#post_dropdowns.js-hide-caregiver-search-tag').length) {
    const $caregiverHoodOption = $(`#post_group_id option[value=${$('#post_dropdowns').data('group-exception-id')}]`)
    const $caregiverTagOption = $("#post_tag option[value='2']")
    // Hide the caregiver tag option on initial page load
    $caregiverTagOption.hide()
    $('#post_group_id').change(function () {
      // If caregiver tag is selected and a user changes the group to something
      // besides the correct subgroups, we reset the tag select dropdown.
      if ($caregiverTagOption.is(':selected') && !$caregiverHoodOption.is(':selected')) {
        $('#post_tag').prop('selectedIndex', 0)
      }
      // If the correct subgroup is selected, we show the caregiver tag option,
      // otherwise we hide it
      if ($caregiverHoodOption.is(':selected')) {
        $caregiverTagOption.show()
      } else {
        $caregiverTagOption.hide()
      }
    })
  }
})
