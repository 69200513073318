$(() => {
  const baseSelector = 'p-program-sections-enrolled'
  if (document.querySelector(`.${baseSelector}__topic-selector`) === null) return

  $(`.${baseSelector}__topic-selector-container__view-all`).click(() => {
    $(`.${baseSelector}__topic-selector-container`).addClass(`${baseSelector}__topic-selector-container--view-all`)
  })

  $(`.${baseSelector}__topic-selector-container__hide`).click(() => {
    $(`.${baseSelector}__topic-selector-container`).removeClass(`${baseSelector}__topic-selector-container--view-all`)
  })
})
