import './post-form-select-group.css'
import {
  toggleSpecialOptions,
  toggleAnnouncementOptions,
  cleanSelection,
} from '../post-form-select-tag/post-form-select-tag'

export function addPostFormSelectGroupListener(formData) {
  $('#post_group_id').change(function () {
    check_anonymous($(this).val())
    checkSpecialTags(formData.specialGroupId, this)
    checkAnnouncements(formData.moderatedGroups, formData.announcements, this)
    trimIndents(this)
    cleanSelection()
  })
}

function check_anonymous(id) {
  if (gon.group_allows_anonymous[id]) {
    $('#anondiv').show()
  } else {
    // uncheck the box
    if ($('#post_isanonymous').prop('checked')) $('#post_isanonymous').click()
    $('#anondiv').hide().find('input').prop('checked', false)
  }
}

function trimIndents(selectedElem) {
  // Trim the indents on select
  const option = selectedElem.options[selectedElem.selectedIndex]
  option.text = option.text.trim()
}

function checkAnnouncements(moderatedGroups, announcement, selectedElem) {
  toggleAnnouncementOptions(announcement, moderatedGroups.includes(selectedElem.value))
}

function checkSpecialTags(specialGroupId, selectedElem) {
  toggleSpecialOptions(specialGroupId === selectedElem.value)
}
