import './recorded-workshop-main.css'
import './about.css'
import './chapters.css'
import './takeaways.css'
import getCleanAnchor from 'utils/getCleanAnchor.js'

document.addEventListener('DOMContentLoaded', function () {
  const containerSelector = '.recorded-workshop-main'
  const container = document.querySelector(containerSelector)
  if (container === null) return

  let links = document.querySelectorAll(`${containerSelector}__menu h3`)
  let contents = document.querySelectorAll(`${containerSelector}__body`)

  for (var i = 0; i < links.length; i++) {
    addLinkListener(links, contents, links[i])
  }
  selectInitialHeader(links, contents)

  function selectInitialHeader(links, contents) {
    const anchorHeader = document.querySelector(`${containerSelector}__menu h3[data-target='${getCleanAnchor()}']`)
    if (anchorHeader && isSelectable(anchorHeader)) selectHeader(links, contents, anchorHeader)
    else selectFirstHeader(links, contents)
  }

  function selectFirstHeader(links, contents) {
    for (var i = 0; i < links.length; i++) {
      if (isSelectable(links[i])) return selectHeader(links, contents, links[i])
    }
  }

  function isSelectable(header) {
    return getComputedStyle(header, null).display !== 'none'
  }

  function addLinkListener(links, contents, link) {
    link.addEventListener('click', function () {
      if (this.classList.contains('selected')) return
      selectHeader(links, contents, this)
    })
  }

  function selectHeader(links, contents, link) {
    for (let j = 0; j < links.length; j++) {
      links[j].classList.remove('selected')
    }
    for (let j = 0; j < links.length; j++) {
      contents[j].classList.remove('selected')
    }
    link.classList.add('selected')
    document
      .querySelector(`${containerSelector}__body[data-content='${link.dataset.target}']`)
      .classList.add('selected')
  }
})
