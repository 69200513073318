$(function () {
  const buttonSelector = '#header-hamburger-icon'
  const menuSelector = '#hamburger-menu'
  if (document.querySelector(menuSelector) === null) return

  $(buttonSelector).click(function (e) {
    $('.header-dropdown.open').removeClass('open')
    e.stopPropagation()
    toggleMenu()
  })

  function toggleMenu() {
    if ($(menuSelector).hasClass('in')) closeHamburgerMenu()
    else openHamburgerMenu()
  }

  function openHamburgerMenu() {
    $('html').addClass('modal-open')
    $(menuSelector).addClass('in')
    $(menuSelector).trigger('show.bs.modal')
  }

  function closeHamburgerMenu() {
    $('html').removeClass('modal-open')
    $(menuSelector).removeClass('in')
    $(menuSelector).trigger('hide.bs.modal')
  }
})
