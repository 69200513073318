import './amazon-pay-button.css'

/* reference http://amzn.github.io/amazon-pay-sdk-samples/code_generator/ */
const selector = '.c-amazon-pay-button'
const fallbackPath = '/subscriptions/buy/amazon_confirm'

export default function amazonPayButton() {
  const component = document.querySelector(selector)
  if (component) {
    amazon.Login.setClientId('amzn1.application-oa2-client.b6408583275d437a81b101dd0ee2797d')
    const redirectUrl = component.dataset.redirectUrl || gon.amazon_redirect || fallbackPath
    createAmazonPayButton(document.querySelector(`${selector}__container`).id, gon.amazon_merchant_id, redirectUrl, {})
  }
}

// https://developer.amazon.com/docs/amazon-pay-onetime/add-a-button.html
function createAmazonPayButton(containerId, merchantId, redirectUrl, options) {
  OffAmazonPayments.Button(containerId, merchantId, {
    type: options.type || 'PwA',
    color: options.color || 'Gold',
    size: options.size || 'large',
    authorization: function () {
      authorize(redirectUrl, getEmailFromForm())
    },
  })
}

function authorize(redirectUrl, email) {
  const loginOptions = {
    scope: 'profile:user_id payments:widget',
    popup: true,
  }
  // Assume that there already is a query string at end of url for amazon
  if (email) redirectUrl = `${redirectUrl}&email=${email}`
  amazon.Login.authorize(loginOptions, redirectUrl, function () {})
}

function getEmailFromForm() {
  const elem = document.querySelector('.c-amazon-pay-form__email-element, .c-stripe-form__email-element')
  return elem ? encodeURIComponent(elem.value) : elem
}
