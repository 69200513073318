import { CourseLesson } from './lesson'

const PAUSED_CLASS_NAME = 'c-panel-step--paused'

export class SupplementalVideoLesson extends CourseLesson {
  subtitle: string
  sourceUrl: string
  sourceType: string
  previewImageUrl: string

  constructor(lessonEl: HTMLDivElement) {
    super(lessonEl)
    this.sourceUrl = this.htmlEl.dataset.sourceUrl ?? ''
    this.sourceType = this.htmlEl.dataset.sourceType ?? ''
    this.subtitle = this.htmlEl.dataset.subtitle ?? ''
    this.previewImageUrl = this.htmlEl.dataset.previewImageUrl ?? ''
  }

  markNotPaused() {
    this.htmlEl.classList.remove(PAUSED_CLASS_NAME)
  }

  markPaused() {
    this.htmlEl.classList.add(PAUSED_CLASS_NAME)
  }
}
