/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  // adjust size of text box (and limit to 204px high - Lindsey spec)
  $('.messages.form .autosize').on('autosize:resized', function () {
    const formHeight = $('.messages.form').outerHeight()
    $('.main-conversation .message-container')
      .css('height', `calc(100% - ${formHeight}px)`)
      .css('min-height', `calc(100% - ${formHeight}px)`)
  })

  if ($('#conversation-welcome-message').length) {
    $('#conversation-welcome-message').modal()
  }
})

const scrollMessages = function () {
  const $body = $('.message-container')
  if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
    if ($body.hasClass('scroll-bottom')) {
      setTimeout(() => $body.scrollTop($body.get(0).scrollHeight + $body.get(0).clientHeight), 10)
    }
  } else {
    $body.scrollTop($body.get(0).scrollHeight - $body.get(0).clientHeight)
  }
}
window.scrollMessages = scrollMessages

$(function () {
  if ($('.message-container').length) {
    if ($('.messaging-subscription').length) {
      const height = $('.messaging-subscription').height()
      $('.main-conversation .message-container')
        .css('height', `calc(100% - ${height}px)`)
        .css('min-height', `calc(100% - ${height}px)`)
    }
    scrollMessages()
  }
})

$(function () {
  const section = '.conversations.mobile-breastfeeding-upsell'
  if ($(section).length && $(section).css('display') !== 'none') {
    $(`${section} .js-open-container`).click(function () {
      $(section).addClass('open')
      $(`${section} + div`).one('click', function () {
        $(section).removeClass('open')
      })
    })

    $(`${section} .js-close-container`).click(function () {
      $(section).removeClass('open')
    })
  }
})
