/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function () {
  // Override the default image upload button because it's ugly and use custom
  // button instead.
  $('#article-image-upload-link').on('click', function (e) {
    e.preventDefault()
    $('#article-image-upload:hidden').trigger('click')
  })
})

$(function () {
  if ($('.articles-admin-table').length) {
    $('.articles-admin-table').DataTable({
      ordering: false,
      lengthChange: false,
      iDisplayLength: 10,
    })
    return
  }
})

$(function () {
  const numElements = $('.article-preview-content').length
  const pageSize = Math.ceil(numElements / 6)
  const pageLength = 6
  for (let n = 1, end = pageSize, asc = 1 <= end; asc ? n <= end : n >= end; asc ? n++ : n--) {
    $('#pagin').append('<li><a href="#">' + n + '</a></li>')
  }
  $('#pagin li a').first().addClass('current')

  const showPage = function (page) {
    $('.article-preview-content').hide()
    $('.article-preview-content').each(function (n) {
      if (n >= pageLength * (page - 1) && n < pageLength * page) {
        $(this).show()
      }
    })
  }

  showPage(1)
  $('#pagin li a').click(function () {
    $('#pagin li a').removeClass('current')
    $(this).addClass('current')
    showPage(parseInt($(this).text()))
  })
})

$(function () {
  $('#article-image-upload').change(function () {
    readURLArticle(this)
  })
})
// This is for previewing an attached image
var readURLArticle = function (input) {
  if (input.files && input.files[0]) {
    $(input.files).each(function () {
      const reader = new FileReader()
      reader.readAsDataURL(this)

      reader.onload = function (e) {
        $('.article_file_image_preview_div').css({ display: 'block' })
        $('.article_file_image_preview_div').append(
          "<img class='article_file_image_preview' src='" + e.target.result + "'>"
        )
      }
    })
  }
}
