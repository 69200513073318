import { CourseView, CourseViewUpdater } from './course-view'
import { Lesson } from './lessons/lesson'
import { SupplementalVideoLesson } from './lessons/supplemental-video-lesson'
import { SupplementalVideoOverlay } from './overlays/supplemental-video-overlay'
import { VideoPlayer } from './video-player'

export class SupplementalVideoOverlayUpdater implements CourseViewUpdater {
  private overlay: SupplementalVideoOverlay
  private player: VideoPlayer
  private previousLesson: Lesson | undefined

  constructor(overlay: SupplementalVideoOverlay, player: VideoPlayer) {
    this.overlay = overlay
    this.player = player
  }

  run(courseView: CourseView) {
    const lesson = courseView.getCurrentLesson()
    const nextLesson = courseView.getNextLesson()
    const lessonAlreadyActive = lesson.id === this.previousLesson?.id

    if (lesson instanceof SupplementalVideoLesson) {
      if (lessonAlreadyActive) {
        return
      }

      this.overlay.show(
        lesson.title,
        lesson.subtitle,
        lesson.previewImageUrl,
        () => {
          this.overlay.hide()
          this.player.play()
        },
        nextLesson ? () => courseView.activate(nextLesson) : undefined
      )
    } else {
      this.overlay.hide()
    }

    this.previousLesson = lesson
  }
}
