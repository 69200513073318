import { Workshop } from '../workshops/workshop'
import { Lesson } from './lesson'
import { LessonsCollection } from './lessons-collection'

export class LessonsIterator {
  private collection: LessonsCollection
  private workshopPosition: number = 0
  private lessonPosition: number = 0

  constructor(collection: LessonsCollection) {
    this.collection = collection
  }

  public restart() {
    this.workshopPosition = 0
    this.lessonPosition = 0
  }

  public current(): Lesson {
    const workshops = this.collection.getWorkshops()
    return workshops[this.workshopPosition].lessons[this.lessonPosition]
  }

  public currentWorkshop(): Workshop {
    return this.collection.getWorkshops()[this.workshopPosition]
  }

  public getNext(): Lesson | undefined {
    const workshops = this.collection.getWorkshops()
    const workshop = workshops[this.workshopPosition]
    if (this.lessonPosition + 1 < workshop.lessons.length) {
      return workshop.lessons[this.lessonPosition + 1]
    } else if (this.workshopPosition + 1 < workshops.length) {
      return workshops[this.workshopPosition + 1].lessons[0]
    } else {
      return undefined
    }
  }

  public hasMoreLessons(): boolean {
    const workshops = this.collection.getWorkshops()
    const workshopHasMoreLessons = this.lessonPosition + 1 < workshops[this.workshopPosition].lessons.length
    const courseHasMoreWorkshops = this.workshopPosition + 1 < workshops.length
    return workshopHasMoreLessons || courseHasMoreWorkshops
  }

  public jumpToLesson(lesson: Lesson): Lesson {
    const workshops = this.collection.getWorkshops()

    for (let wsIndex = 0; wsIndex < workshops.length; wsIndex++) {
      const workshop = workshops[wsIndex]
      const lessons = workshop.lessons

      for (let lsnIndex = 0; lsnIndex < lessons.length; lsnIndex++) {
        if (lesson.id === lessons[lsnIndex].id) {
          this.workshopPosition = wsIndex
          this.lessonPosition = lsnIndex
          return lesson
        }
      }
    }

    return lesson
  }
}
