import './video-modal--brightcove.css'

const videoKlass = 'c-video-modal--brightcove__video'
let existingPlayerData = {}
let myPlayer
// +++ Set the data for the player +++
function defaultPlayerData() {
  const containerSelector = '.c-video-modal--brightcove__placeholder'
  const container = document.querySelector(containerSelector)
  return JSON.parse(container.dataset.brightcoveArgs)
}

export function getPlayer() {
  return myPlayer
}

export function addPlayer(playerDataArgs, title) {
  setTitle(title)
  const playerData = { ...defaultPlayerData(), ...playerDataArgs }
  if (myPlayer) {
    if (hasSamePlayer(existingPlayerData, playerData)) return simpleChangeVideo(playerData.videoId)
    else myPlayer.dispose()
  }
  existingPlayerData = playerData

  // Dynamically build the player video element
  const playerHTML =
    `<video-js class="${videoKlass}" data-video-id="` +
    playerData.videoId +
    '"  data-account="' +
    playerData.accountId +
    '" data-player="' +
    playerData.playerId +
    '" data-application-id="' +
    playerData.applicationId +
    '" data-embed="default" class="video-js" controls></video-js>'
  // Inject the player code into the DOM
  const containerSelector = '.c-video-modal--brightcove__placeholder'
  document.querySelector(containerSelector).innerHTML = playerHTML
  // Add and execute the player script tag
  var s = document.createElement('script')
  s.src = 'https://players.brightcove.net/' + playerData.accountId + '/' + playerData.playerId + '_default/index.min.js'
  // Add the script tag to the document
  document.body.appendChild(s)
  // Call a function to play the video once player's JavaScropt loaded
  s.onload = callback
}

// +++ Initialize the player and start the video +++
function callback() {
  myPlayer = bc(document.querySelector(`.${videoKlass}`))
  myPlayer.on('loadedmetadata', function () {
    // Mute the audio track, if there is one, so video will autoplay on button click
    // myPlayer.muted(true);
    myPlayer.play()
  })
}

function setTitle(title) {
  const containerSelector = '.c-video-modal--brightcove__title'
  document.querySelector(containerSelector).innerHTML = title
}

function hasSamePlayer(oldData, newData) {
  return (
    oldData.accountId === newData.accountId &&
    oldData.applicationId === newData.applicationId &&
    oldData.playerId === newData.playerId
  )
}

function simpleChangeVideo(videoId) {
  existingPlayerData.videoId = videoId
  myPlayer.catalog.getVideo(videoId, function (error, video) {
    myPlayer.catalog.load(video)
    myPlayer.play()
  })
}
